import React, {Component} from 'react';

class Contact extends Component {
    render() {
        return (
            <div className="container mb-5 mt-5">
                <h4 style={{fontFamily: "FuturaStd-Bold", fontSize: "23px"}}> Contatti</h4>
                <p>e-mail: servizioclienti@vivoacasatua.com</p>
                <p>telefono: 0985/271393</p>

            </div>
        );
    }
}

export default Contact;