import React from "react"
import Backdrop from "../Backdrop/Backdrop";

export default class Modal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: this.props.show
        }
    }

    componentDidMount() {
        if (this.state.open) {
            document.body.style.overflow = 'hidden';
        }
    }

    componentWillUnmount() {
        document.body.style.overflow = 'unset';
    }

    render() {
        const { show, close } = this.props;
        return (
            <>
                <Backdrop show={show} click={close}></Backdrop>
                <div className="Modal"
                    style={{
                        transform: show ? "translateY(0)" : "translateY(-100vh)",
                        opacity: show ? "1" : "0"
                    }}
                >
                    <div className="d-flex flex-row-reverse pr-2 pr-sm-3 pt-2">
                        <button className="modal-close outline" onClick={close}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    {this.props.children}
                </div>
            </>
        );
    }
}