import React from "react";
import config from "../../../../config/config";
import { Link, withRouter } from "react-router-dom"
import { Translation } from 'react-i18next';

import { ReactComponent as PinIcon } from '../../../../images/pin.svg';
import { ReactComponent as ShopIcon } from '../../../../images/shop.svg';
import { ReactComponent as VivoLogo } from '../../../../images/vivo-logo.svg';

import HeaderItem from "../HeaderItems/HeaderItem/HeaderItem";
import HeaderLink from "../HeaderItems/HeaderLink/HeaderLink";
import HeaderCategoriesItem from "../HeaderItems/HeaderCategoriesItem/HeaderCategoriesItem";

import "./mobile-header.styles.scss";


class MobileHeader extends React.Component {

    updateCart = () => {
        if (this.props.user && config.SHOW_CART_UPDATE) {
            return (
                <Link className="pin-link" to="/cart/update">
                    <nobr>
                        <span>{this.props.cart && this.props.cart.storeDescription ?
                            this.props.cart.storeDescription : config.DEFAULT_STORE_DESCRIPTION}</span>
                    </nobr>
                </Link>
            );
        } else {
            return (
                <nobr>
                    <span>{this.props.cart && this.props.cart.storeDescription ?
                        this.props.cart.storeDescription : config.DEFAULT_STORE_DESCRIPTION}</span>
                </nobr>
            );
        }
    }

    render() {
        const { user, cart, searchInput, onChange, closeAll } = this.props;
        return (
            <Translation>
                {t =>
                    <div className="mobile-header">
                        <div className="mobile-header-wrapper">
                            <div className="container-fluid">

                                <div className="row mobile-header-top">
                                    <div className="col-8">
                                        <PinIcon className="pin" />
                                        {this.updateCart()}
                                    </div>
                                    <div className="col-4 text-right">
                                        <Link className="shop-link" to="/stores">
                                            <ShopIcon className="shop" />
                                            <span>{t("stores")}</span>
                                        </Link>
                                    </div>
                                </div>

                                <div className="row mobile-header-bottom">
                                    <div className="col-4">
                                        <div className="logo align-items-center">
                                            <Link to="/" onClick={closeAll}>
                                                <VivoLogo />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <div className="d-flex justify-content-end">
                                            <HeaderCategoriesItem
                                                label="products"
                                                alwaysActived={false}
                                                onClick={this.props.categoriesModal}
                                                actived={this.props.showCategories}
                                            />
                                            <HeaderItem
                                                icon="user"
                                                label={user ? user.firstName : "access"}
                                                alwaysActived={false}
                                                onClick={this.props.loginModal}
                                                actived={this.props.showLogin}
                                            />
                                            {user &&
                                                <HeaderLink
                                                    icon="shopping-cart"
                                                    path="/cart"
                                                    footer={this.props.renderTotal(cart)}
                                                    items={cart ? cart.itemsCount : "0"}
                                                    closeAll={this.props.closeAll}
                                                />
                                            }
                                            {!user &&
                                                <HeaderItem
                                                    icon="shopping-cart"
                                                    label="cart"
                                                    alwaysActived={false}
                                                    onClick={this.props.loginModal}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {(this.props.location.pathname === "/" ||
                                this.props.location.pathname === "/category" ||
                                this.props.location.pathname === "/search" ||
                                this.props.location.pathname === "/cart" ||
                                this.props.location.pathname.includes("/product")
                            ) &&
                                <div className="mobile-header-search">
                                    <form onSubmit={e => {
                                        e.preventDefault();
                                        this.props.history.push({
                                            pathname: "/search",
                                            state: { searchInput: searchInput }
                                        })
                                        closeAll();
                                    }}>
                                        <input type="search" onChange={onChange} value={searchInput}
                                            placeholder="Ricerca prodotto o categoria"></input>
                                        <button type="submit" className="outline">
                                            <span>{t("search")}</span>
                                        </button>
                                    </form>
                                </div>
                            }
                        </div>
                    </div>
                }
            </Translation>
        )
    }
}

export default withRouter(MobileHeader)