import i18n from "../../i18n"
import React from "react";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import CurrentCart from "./CurrentCart/CurrentCart"
import Carts from "./Carts/Carts"
import CartMenu from "./CartMenu/CartMenu"
import { Translation } from 'react-i18next';
import { connect } from "react-redux"
import * as actionCreators from "../../redux/store/actions/index"
import { Redirect } from 'react-router'
import FreezeCheckPopup from "./CartMenu/FreezeCheck/FreezeCheckPopup";
import { ecommerceCartsService } from "../../services/ecommerce/EcommerceCartsService";

class Cart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCarts: false,
    };
  }

  componentDidMount() {
    this.props.current();
    if (this.props.cart) {
      ecommerceCartsService.freezeCheck(this.props.cart.id, false)
        .then(response => {
          if (response && response.responseCode === 4105) {
            this.props.freezeCheck(response.data, true);
          }
        })
    }
  }

  changeView = () => {
    this.setState({
      showCarts: !this.state.showCarts
    })
  }

  renderCarts = (cart) => {
    return this.state.showCarts ? <Carts /> : <CurrentCart cart={cart}></CurrentCart>
  }

  render() {
    const label = this.state.showCarts ? <span>{i18n.t("cart.current")}</span> : <span>{i18n.t("cart.lists")}</span>
    const { cart, updatedItems, removedItems, showFreezePopup, closeFreezePopup } = this.props;
    if (cart) {
      return (
        <Translation>
          {t =>
            <>
              <div className="container-fluid">
                <Row>
                  <Col sm={12} xl={7}>
                    <div className="cart-container">
                      {this.renderCarts(cart)}
                    </div>
                  </Col>
                  <Col className="p-0" style={{ backgroundColor: "#f7f7f7" }} sm={12} xl={5}>
                    <CartMenu
                      changeView={this.changeView}
                      label={label}
                    />
                  </Col>
                </Row>
              </div>
              <FreezeCheckPopup
                open={showFreezePopup}
                closePopup={closeFreezePopup}
                updatedItems={updatedItems}
                removedItems={removedItems}
              />
            </>
          }
        </Translation>
      );
    } else return <Redirect to='/cart/create' />
  }
}

const mapStateToProps = state => {
  return {
    cart: state.cart.cart,
    updatedItems: state.cart.updatedItems,
    removedItems: state.cart.removedItems,
    showFreezePopup: state.cart.showFreezePopup
  };
}

const mapDispatchToProps = dispatch => {
  return {
    current: () => dispatch(actionCreators.current()),
    freezeCheck: (data, showPopup) => dispatch(actionCreators.freezeCheck(data, showPopup)),
    closeFreezePopup: () => dispatch(actionCreators.closeFreezePopup()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Cart);

