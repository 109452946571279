import React from "react"
import CustomInput from "../../../../components/UI/Input/CustomInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Translation} from "react-i18next";
import BillingAddressPopup from "../AddressPopup/BillingAddressPopup";

const AddressCard = ({address, index, action, deleteAddress}) => (
    <Translation>
        {t =>
            <div className="profile-addresses-billing">
                <div className="address-header">
                    <div className="address-title">Indirizzo {index + 1}</div>
                    <BillingAddressPopup
                        address={address}
                        action={action}
                        deleteAddress={deleteAddress}
                        title={t("addresses.update")}
                        button={t("save")}
                        isUpdate={true}
                        trigger={
                            <button className="update-button outline">
                                <FontAwesomeIcon icon="edit"/>
                                <span>Modifica</span>
                            </button>
                        }
                    />
                </div>
                <div className="address-info">
                    {/* <div className="row address-row">
                        <div className="col-8 col-sm-6">
                            <CustomInput
                                label="Codice cliente"
                                type="text"
                                value={address && address.customerCode}
                                disabled
                            />
                        </div>
                    </div> */}
                    <div className="row address-row">
                        <div className="col-8 col-sm-6">
                            <CustomInput
                                label="Partita iva"
                                type="text"
                                value={address && address.vatCode}
                                disabled
                            />
                        </div>
                        <div className="col-8 col-sm-6">
                            <CustomInput
                                label="Codice fiscale"
                                type="text"
                                value={address && address.ssnCode.toUpperCase()}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="row address-row">
                        <div className="col-8 col-sm-6">
                            <CustomInput
                                label="Ragione sociale"
                                type="text"
                                value={address && address.companyName}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="row address-row">
                        <div className="col-8 col-sm-6">
                            <CustomInput
                                label="Indirizzo"
                                type="text"
                                value={address && address.address}
                                disabled
                            />
                        </div>
                        <div className="col-4 col-sm-2 col-2">
                            <CustomInput
                                label="Civico"
                                type="text"
                                value={address && address.streetNumber.toUpperCase()}
                                disabled
                            />
                        </div>
                        <div className="col-sm-4">
                            <CustomInput
                                label="Cap"
                                type="number"
                                value={address && address.zip}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="row address-row">
                        <div className="col-8 col-sm-6">
                            <CustomInput
                                label="Città"
                                type="text"
                                value={address && address.city}
                                disabled
                            />
                        </div>
                        <div className="col-4 col-sm-2">
                            <CustomInput
                                label="Provincia"
                                type="text"
                                value={address && address.county.toUpperCase()}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="row address-row">
                        <div className="col-8 col-sm-6">
                            <CustomInput
                                label="Telefono"
                                type="number"
                                value={address && address.phone}
                                disabled
                            />
                        </div>
                        <div className="col-8 col-sm-6">
                            <CustomInput
                                label="Fax"
                                type="number"
                                value={address && address.fax}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="row address-row">
                        <div className="col-8 col-sm-6">
                            <CustomInput
                                label="PEC"
                                type="email"
                                value={address && address.pec}
                                disabled
                            />
                        </div>
                        <div className="col-8 col-sm-6">
                            <CustomInput
                                label="Codice destinatario(SDI)"
                                type="text"
                                value={address && address.recipientCode}
                                disabled
                            />
                        </div>
                    </div>
                    {/* <div className="row address-row">
                        <div className="col-4 col-sm-2">
                            <CustomInput
                                label="Soggetto"
                                type="text"
                                value={address && address.subject}
                                disabled
                            />
                        </div>
                        <div className="col-8 col-sm-6">
                            <CustomInput
                                label="Nome"
                                type="text"
                                value={address && address.name}
                                disabled
                            />
                        </div>
                    </div> */}
                </div>
            </div>
        }
    </Translation>
);

export default AddressCard;