import React from "react";
import Row from "react-bootstrap/Row"
import Disponibility from "../Checkout/Disponibility/Disponibility";
import { connect } from "react-redux"

class Availability extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            day: null,
            timeSlotId: null,
        }
    }

    selectDay = (day) => {
        if (this.state.day) {
            document.getElementById(this.state.day).classList.remove("disponibility-button-selected");
        }
        this.setState({ day: day })
        document.getElementById(day).classList.add("disponibility-button-selected");
    }

    selectTimeslot = (timeSlotId) => {
        if (this.state.timeSlotId) {
            if (document.getElementById(this.state.timeSlotId)) {
                document.getElementById(this.state.timeSlotId).classList.remove("pill-active");
            }
        }
        this.setState({ timeSlotId: timeSlotId })
        document.getElementById(timeSlotId).classList.add("pill-active");
    }

    render() {
        return (
            <div className="container-fluid h-100">
                <Row className="g-padding">
                    <div className="navigation-view container-fluid">
                        <Disponibility
                            selectDay={this.selectDay}
                            selectTimeslot={this.selectTimeslot}
                            day={this.state.day}
                            cart={this.props.cart}
                        />
                    </div>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
      cart: state.cart.cart
    };
  }
  
export default connect(mapStateToProps)(Availability);
