import React, {Component} from 'react';

class CompanyData extends Component {
    render() {
        return (
            <div className="container mb-5 mt-5">
                <h4 style={{marginTop: "40px", fontFamily: "FuturaStd-Bold", fontSize: "23px"}}>
                    Dati Societari
                </h4>
                <div>
                    <p>
                        C.D. GEST – S.R.L.
                    </p>
                    <p>Con sede legale in Scalea (CS), via Unità D’Italia C.da La Bruca snc, capitale sociale Euro
                        10.400,00 i.v., codice fiscale partita iva e numero di iscrizione al Registro delle Imprese di
                        Cosenza 02241230784.
                        D.C. SERVICES – S.R.L.
                    </p>
                    <p>Con sede legale in Orsomarso (CS), via Ziccarelli 77/1, capitale sociale Euro 12.000,00 i.v.,
                        codice
                        fiscale partita iva e numero di iscrizione al Registro delle Imprese di Cosenza 02933820785.
                    </p>
                </div>

            </div>
        );
    }
}

export default CompanyData;