import React from "react"
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Placeholder from "../../images/product-placeholder.png"
import CounterInput from "../../components/UI/Counter/CounterInput";
import info from "./Service"
import ProductInfo from "./ProductInfo/ProductInfo";
import GoBackButton from "../../components/UI/Buttons/GoBackButton";
import FavoriteButton from "../../components/UI/Buttons/FavoriteButton";
import { Translation } from "react-i18next";
import { connect } from "react-redux"
import * as actionCreators from "../../redux/store/actions/index"
import config from "../../config/config";
import ImageGallery from 'react-image-gallery';
import { ReactComponent as PiecesPerSetIcon } from '../../images/piecesPerSet.svg';
import { ReactComponent as MaxIcon } from '../../images/tags/ico_so_max.svg';
import { ReactComponent as PesoVarIcon } from '../../images/tags/ico_so-pesovar2.svg';
import { tags } from "../../utils/renderTags";
import { withRouter } from 'react-router-dom'

class Product extends React.Component {
    constructor(props) {
        super(props);
        const isDetail = this.props.location.state ? this.props.location.state.isDetail : null;
        const product = this.props.location.state ? this.props.location.state.product : null;
        let initialAmount = null;
        if (product) {
            if (isDetail) {
                if (product.um === "PZ") {
                    initialAmount = product.amount;
                } else {
                    initialAmount = product.weight;
                }
            } else {
                initialAmount = product.umStep;
            }
        }

        let isFavorite = false;
        if (this.props.favorites) {
            this.props.favorites.forEach(item => {
                if (this.props.match.params.barcode === item.barcode) {
                    isFavorite = true;
                }
            });
        }

        this.state = {
            amount: initialAmount,
            umStep: product ? product.umStep : null,
            info: [],
            isFavorite: isFavorite,
            available: isDetail ? true : product.available
        }
    }

    componentDidMount() {
        const barcode = this.props.match.params.barcode;
        info(barcode).then(response => {
            if (response.data) {
                this.setState({
                    info: response.data,
                })
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    renderPromo = (item) => {
        if (item.promotions) {
            if (item.promotions[0] !== null && item.promotions[0] !== undefined) {
                // const label = item.promotions[0].valueType === "UNKNOWN" ? "promo.unknown" : "promo";
                return (
                    <Col className="product-detail-promotion" sm={12}>
                        <span>
                            Offerta!
                        </span>
                    </Col>
                );
            } else {
                return null;
            }
        }
    }

    incrementAmount = () => {
        let newAmount = this.state.amount + this.state.umStep;
        newAmount = newAmount.toFixed(2);
        newAmount = parseFloat(newAmount);
        this.setState({ amount: newAmount })
    }

    decrementAmount = () => {
        let newAmount = this.state.amount - this.state.umStep;
        newAmount = newAmount.toFixed(2);
        newAmount = parseFloat(newAmount);
        if (this.state.amount > this.state.umStep) {
            this.setState({ amount: newAmount })
        }
    }

    componentDidUpdate(prevPros) {
        if (prevPros.location.state.product.id !== this.props.location.state.product.id) {
            const barcode = this.props.match.params.barcode;
            info(barcode).then(response => {
                this.setState({
                    info: response.data,
                    amount: this.props.location.state.product.umStep,
                    umStep: this.props.location.state.product.umStep
                })
            }).catch((error) => {
                console.log(error);
            })
        }
    }

    renderPrices = (item) => {
        let result;
        if (item.price !== item.netPrice) {
            result =
                <>
                    <span className="mr-1 text-strike product-price">
                        {`${(item.price * this.state.amount).toFixed(2)} \u20AC`}
                    </span>
                    <span className="primary-color product-price ml-4">
                        {`${(item.netPrice * this.state.amount).toFixed(2)} \u20AC`}
                    </span>
                </>
        } else {
            result = <span className="product-price">{`${(item.netPrice * this.state.amount).toFixed(2)} \u20AC`}</span>
        }
        return result
    }

    setFavorite = () => {
        const barcode = this.props.match.params.barcode;
        if (this.state.isFavorite) {
            this.props.deleteFavorite(barcode);
        } else {
            this.props.addFavorite(barcode);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.favorites.length - this.props.favorites.length === 1) {
            this.setState({
                isFavorite: true
            })
        }
        if (this.props.favorites.length - nextProps.favorites.length === 1) {
            this.setState({
                isFavorite: false
            })
        }
    }

    roundWeigth = (n) => {
        // es 533 => 540
        n = n / 10;
        n = Math.ceil(n);
        n = n * 10;
        n = n * this.state.amount;
        if (n >= 1000) {
            return `${n / 1000}kg`
        } else {
            return `${n}g`
        }
    }

    goPrevPath = () => {
        const { from, product, category, mainCategory, categoryTitle, subCategory, activeKey } = this.props.location.state;
        if (from) this.props.history.push({
            pathname: `/category`,
            state: { activeKey, categoryId: product.categoryId, category, mainCategory, categoryTitle, subCategory, from: this.props.location.pathname }
        })
        else this.props.history.goBack()
    }

    render() {
        const { product } = this.props.location.state;
        console.log(product);
        const productImages = product.images && product.images.map(item => {
            return { original: item }
        })
        const { info, isFavorite, available } = this.state;
        const { user, cart, store, setProductToAdd } = this.props;
        return (
            <Translation>
                {t =>
                    <div className="text-center container-fluid mobile-view-search">
                        <Row>
                            <Col className="p-0" sm={12} md={12} lg={7} style={{ backgroundColor: "#ffffff" }}>
                                <div className="container-fluid">
                                    <Row style={{ paddingTop: "25px", paddingBottom: '10px' }}>
                                        <Col sm={5} className="px-0 d-flex back-button-pl">
                                            <GoBackButton goBack={() => this.goPrevPath()}>
                                                {t("goBack")}
                                            </GoBackButton>
                                        </Col>
                                    </Row>
                                    <div style={{ marginBottom: "15px", paddingTop: "unset" }}
                                        className="view-container">
                                        {product && product.images && product.images.length > 0 &&
                                            <ImageGallery
                                                items={productImages}
                                                showFullscreenButton={false}
                                                autoPlay={false}
                                                showBullets={true}
                                                showPlayButton={false}
                                                showThumbnails={false}
                                                onErrorImageURL={Placeholder}
                                            />
                                        }
                                        {product && !product.images &&
                                            <div className="product-detail">
                                                <img src={product.imageUrl ? product.imageUrl : Placeholder} alt=""
                                                    onError={(e) => { e.target.src = Placeholder }}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </Col>
                            <Col className="p-0" style={{ backgroundColor: "#f7f7f7" }} sm={12} md={12} lg={5}>
                                <div className="g-lg-padding">
                                    <div className="view-container">
                                        <Row>
                                            {product.attrib4 &&
                                                <Col className="pb-2 text-center">
                                                    <img width="100px" src={product.brandImage} alt="" />
                                                </Col>
                                            }
                                            {user && config.ENABLED_SLIST &&
                                                <Col className="px-0 d-flex pb-5">
                                                    <FavoriteButton
                                                        isFavorite={isFavorite}
                                                        setFavorite={this.setFavorite}
                                                    />
                                                </Col>
                                            }
                                            <Col sm={12}>
                                                <h1 className="product-detail-name cart-product-description">{product.description}</h1>
                                            </Col>
                                            {product.ref &&
                                                <Col sm={12}>
                                                    <span className="product-detail-ref">
                                                        {t("ref")}: {product.ref}
                                                    </span>
                                                </Col>
                                            }
                                            <Col sm={12}>
                                                {product.tags &&
                                                    tags.renderDetailTags(product.tags, product)
                                                }
                                                {/* {product.tags.split(",")[0] === "MAXI_FORMATO" &&
                                                    product.attrib1 &&  `${product.weight} ${product.attrib1}`
                                                } */}
                                                {product.piecesPerSet &&
                                                    <span className="product-detail-heading">
                                                        <span className="pr-2"><PiecesPerSetIcon title={"Unità di vendita"} /></span>
                                                        {t("piecesPerSet")}: {product.piecesPerSet} PZ
                                                    </span>
                                                }
                                                {product.maxAmount &&
                                                    <span className="product-detail-heading">
                                                        <span className="pr-2"><MaxIcon title={"Massimo pezzi acquistabili"} /></span>
                                                        {t("maxAmount")}: {product.maxAmount} PZ
                                                    </span>
                                                }
                                                {product.vatConfigurationId === "KG" &&
                                                    <span className="product-detail-heading">
                                                        <span className="pr-2"><PesoVarIcon title={"Prodotto a peso variabile"} /></span>
                                                        {t("variableWeight")}
                                                    </span>
                                                }
                                            </Col>
                                            {this.renderPromo(product)}
                                            {product.price &&
                                                <>
                                                    <Col sm={12}>
                                                        <span className="product-detail-heading">{t("price")}</span>
                                                    </Col>
                                                    <Col sm={12}>
                                                        {this.renderPrices(product)}
                                                        {product.vatConfigurationId &&
                                                            product.vatConfigurationId === "KG" &&
                                                            product.weight &&
                                                            <span> ({this.roundWeigth(product.weight * 1000)} Circa)</span>
                                                        }
                                                    </Col>
                                                </>
                                            }
                                            <Col sm={12}>
                                                <h4 className="product-detail-netPrice pt-3 mb-3">
                                                    {product.attrib1 && product.attrib3 && `${product.attrib3} \u20AC / ${product.attrib1} `}
                                                </h4>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12}>
                                                <div className="container-fluid px-0 mx-0 product-detail-actions-container">
                                                    {available &&
                                                        <Row>
                                                            <Col sm={12}>
                                                                <CounterInput
                                                                    value={this.state.amount}
                                                                    um={product.um}
                                                                    increment={this.incrementAmount}
                                                                    decrement={this.decrementAmount}
                                                                />
                                                            </Col>
                                                            <Col className="offset-0 offset-sm-2" sm={8}>
                                                                {user && cart &&
                                                                    <div className="mb-3">
                                                                        <button
                                                                            className="custom-button outline text-center"
                                                                            onClick={() => cart && store(cart.id, product.barcode, this.state.amount, product.um)}>
                                                                            {t("buy")}
                                                                        </button>
                                                                    </div>
                                                                }
                                                                {user && !cart &&
                                                                    <div className="mb-3">
                                                                        <button
                                                                            className="custom-button outline"
                                                                            onClick={() => {
                                                                                setProductToAdd(product, this.state.amount);
                                                                                document.getElementById('shopping-cart').click();
                                                                            }}>
                                                                            {t("buy")}
                                                                        </button>
                                                                    </div>
                                                                }
                                                                {!user &&
                                                                    <div className="mb-3">
                                                                        <button
                                                                            className="custom-button outline"
                                                                            onClick={() => {
                                                                                setProductToAdd(product, this.state.amount);
                                                                                document.getElementById('user').click();
                                                                            }}>
                                                                            {t("access")}
                                                                        </button>
                                                                    </div>
                                                                }
                                                            </Col>
                                                            {config.ENABLED_SLIST &&
                                                                <Col className="offset-0 offset-sm-2" sm={8}>
                                                                    {user &&
                                                                        <div className="mb-3">
                                                                            <button
                                                                                className="custom-slist-button outline"
                                                                                onClick={() => { this.props.itemCreate(this.props.currentSlist.id, product.barcode, 1) }}>
                                                                                {t("smartlist.add")}
                                                                            </button>
                                                                        </div>
                                                                    }
                                                                    {!user &&
                                                                        <div className="mb-3">
                                                                            <button
                                                                                className="custom-slist-button outline"
                                                                                onClick={() => {
                                                                                    // setProductToAdd(product, this.state.amount);
                                                                                    document.getElementById('user').click();
                                                                                }}>
                                                                                {t("smartlist.add")}
                                                                            </button>
                                                                        </div>
                                                                    }
                                                                </Col>
                                                            }
                                                        </Row>
                                                    }
                                                    {!available &&
                                                        <Row>
                                                            <Col className="offset-0 offset-sm-1" sm={10}>
                                                                <div className="product-unavaible">
                                                                    <span>Momentaneamente non disponibile</span>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <ProductInfo info={info} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                }
            </Translation>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
        cart: state.cart.cart,
        favorites: state.slists.favorites,
        currentSlist: state.slists.currentSlist,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        store: (cartId, barcode, amount, um) => dispatch(actionCreators.store(cartId, barcode, amount, um)),
        setProductToAdd: (item, amount) => dispatch(actionCreators.setProductToAdd(item, amount)),
        getFavorites: () => dispatch(actionCreators.getFavorites()),
        addFavorite: (barcode) => dispatch(actionCreators.addFavorite(barcode)),
        deleteFavorite: (barcode) => dispatch(actionCreators.deleteFavorite(barcode)),
        itemCreate: (listId, barcode, amount) => dispatch(actionCreators.itemCreate(listId, barcode, amount)),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Product))