import React from "react";
import Container from "react-bootstrap/Container"
import AddressCard from "./AddressCard/AddressCard";
import AddressPopup from "./AddressPopup/AddressPopup";
import { Translation } from 'react-i18next';
import { addressesService } from "./Service"
import toaster from "../../../utils/toaster"
import { ReactComponent as AddIcon } from "../../../images/Add.svg"

import "./profile-addresses.styles.scss"

export default class ProfileAddresses extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addresses: [],
        };
    }

    componentDidMount() {
        addressesService.getAddresses()
            .then(response => {
                console.log(response);
                this.setState({
                    addresses: response.data,
                })
            }).catch((error) => {
                console.log(error);
            })
    }

    newAddress = (address) => {
        addressesService.newAddress(address)
            .then(response => {
                console.log(response);
                this.setState({
                    addresses: response.data,
                })
                if (address.id) {
                    toaster.standard("Indirizzo di spedizione aggiornato con successo!")
                } else {
                    toaster.standard("Indirizzo di spedizione aggiunto con successo!")
                }
            }).catch((error) => {
                console.log(error);
            })
    }

    deleteAddress = (addressId) => {
        addressesService.deleteAddress(addressId)
            .then(response => {
                console.log(response);
                this.setState({
                    addresses: response.data,
                })
                toaster.standard("Indirizzo di spedizione eliminato con successo!")
            }).catch((error) => {
                console.log(error);
            })
    }

    render() {
        const { addresses } = this.state;
        return (
            <Translation>
                {t =>
                    <Container className="signup-wrapper profile-addresses">
                        <div className="signup-form">
                            <AddressPopup
                                action={this.newAddress}
                                title={t("addresses.new")}
                                button={t("save")}
                                isUpdate={false}
                                trigger={
                                    <button className="add outline">
                                        <AddIcon className="icon" />
                                        <span className="text">{t("addresses.addButton")}</span>
                                    </button>
                                }
                            />
                            <hr />
                            <div className="profile-addresses-title">{t("addresses.title")}</div>
                            {addresses && addresses.map((address, index) => (
                                <AddressCard
                                    key={address.id}
                                    address={address}
                                    index={index}
                                    action={this.newAddress}
                                    deleteAddress={this.deleteAddress}
                                />
                            ))}
                        </div>
                    </Container>
                }
            </Translation>
        );
    }
}

