import React from "react";
import InfiniteScroll from 'react-infinite-scroll-component';
import Row from "react-bootstrap/Row";
import Loader from 'react-loader-spinner'
import HomeProduct from "../../../components/Products/HomeProduct/HomeProduct";
import { Translation } from 'react-i18next';
import { ecommerceService } from "../../../services/ecommerce/EcommerceService"
import { debounce } from "lodash"
import { connect } from "react-redux"
class SearchResults extends React.Component {
    constructor(props) {
        super(props);
        this.searchProducts = (input, page) => {
            const storeId = this.props.cart ? this.props.cart.storeId : ""
            ecommerceService.searchProducts(input, storeId, page, "", false, "")
                .then(response => {
                    this.setState({
                        products: response.data,
                        totalPages: response.totalPages,
                        page: response.number,
                        hasMore: !response.last
                    })
                }).catch((error) => {
                    console.log(error);
                })
        }

        this.state = {
            products: [],
            page: 0,
            totalPages: null,
            hasMore: true,
        }
    }

    handleInput = debounce((input) => {
        this.searchProducts(input, this.state.page)
    }, 1000)

    componentDidMount() {
        this.handleInput(this.props.input);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.handleInput(nextProps.input);
    }

    loadMore = () => {
        const page = this.state.page + 1;
        if (page < this.state.totalPages) {
            const storeId = this.props.cart ? this.props.cart.storeId : ""
            ecommerceService.searchProducts(this.props.input, storeId, page, "", false, "")
                .then(response => {
                    const newResponse = this.state.products;
                    newResponse.push(...response.data);
                    this.setState({
                        products: newResponse,
                        totalPages: response.totalPages,
                        page: response.number,
                        hasMore: !response.last
                    })
                }).catch((error) => {
                    console.log(error);
                })
        }
    }

    render() {
        const { products, hasMore } = this.state;
        return (
            <Translation>
                {t =>
                    <div id="scrollableDiv" style={{ backgroundColor: "#f7f7f7", overflowY: "auto", height: "700px", marginBottom: "40px" }}>
                        <div className="container-fluid search-product-view">
                            <div className="row h-100">
                                <div className="container-fluid">
                                    <p className="pb-3 pl-lg-5 title">{t("product-results")}</p>
                                    <InfiniteScroll
                                        dataLength={products.length}
                                        next={this.loadMore}
                                        hasMore={hasMore}
                                        loader={<Loader
                                            type="ThreeDots"
                                            color="#333"
                                            height={70}
                                            width={70}
                                            timeout={3000} //3 secs

                                        />}
                                        scrollableTarget="scrollableDiv"
                                    >
                                        <Row className="d-flex justify-content-center">
                                            {products && products.map((item) => (
                                                <HomeProduct key={item.id} item={item} onClick={this.props.onClick} />
                                            ))}
                                        </Row>
                                    </InfiniteScroll>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}

const mapStateToProps = state => {
    return {
        cart: state.cart.cart
    };
}

export default connect(mapStateToProps)(SearchResults);
