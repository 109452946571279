import React from "react"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Dropdown from "../../components/UI/Dropdown/Dropdown"
import StoreSelect from "./StoreSelect/StoreSelect"
import config from "../../config/config"
import { Translation } from "react-i18next"
import { storesService } from "../../services/basis/StoresService"
import { ecommerceService } from "../../services/ecommerce/EcommerceService"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import * as actionCreators from "../../redux/store/actions/index"
import AddressSelect from "./AddressSelect/AddressSelect"
import { Redirect } from 'react-router'

class CartCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            states: [],
            deliveryModes: [],
            stores: [],
            selectedState: config.DEFAULT_STATE_ID,
            selectedDeliveryMode: "",
            selectedStore: "",
            addressId: null
        }
    }

    componentDidMount() {
        storesService.states()
            .then(data => {
                this.setState({
                    states: data
                })
            });
        ecommerceService.deliveryModes()
            .then(data => {
                this.setState({
                    deliveryModes: data
                })
            });
    }

    selectState = (item) => {
        this.setState({ selectedState: item.stateId });
    }

    selectDeliveryMode = (item) => {
        this.setState({ selectedDeliveryMode: item.id });
    }

    selectStore = (item) => {
        this.setState({ selectedStore: item.id });
    }

    selectAddress = (item) => {
        this.setState({ addressId: item.id });
    }

    render() {
        const { states, deliveryModes, selectedState, selectedDeliveryMode, selectedStore, addressId } = this.state;
        const { open, cart } = this.props;
        if (cart) return <Redirect to='/' />
        else return (
            <Translation>
                {t =>
                    <div className="bg">
                        <div className="container">
                            <div className="col-xs-12 col-sm-6 offset-md-6">
                                <div className="wrapper">
                                    <div className="wrapper-box">
                                        <h1 className="cart-create-title">{t("create.cart.title")}</h1>
                                        <div>
                                            <div className="cart-create-select">
                                                {config.SHOW_STATE_DROPDOWN &&
                                                    <Dropdown
                                                        items={states}
                                                        select={this.selectState}
                                                        label={t("create.cart.state")}
                                                    />
                                                }
                                                <Dropdown
                                                    items={deliveryModes}
                                                    select={this.selectDeliveryMode}
                                                    label={t("create.cart.service")}
                                                    isDeliveryMode={true}
                                                />
                                                {(selectedDeliveryMode === "AT_HOME" ||
                                                    selectedDeliveryMode === "SHIPPED") &&
                                                    <AddressSelect
                                                        select={this.selectAddress}
                                                        label="Indirizzo di spedizione"
                                                    />
                                                }
                                                {(selectedDeliveryMode === "IN_STORE" ||
                                                    selectedDeliveryMode === "DRIVE") &&
                                                    <StoreSelect
                                                        state={selectedState}
                                                        deliveryMode={selectedDeliveryMode}
                                                        select={this.selectStore}
                                                        label={t("create.cart.store")}
                                                    />
                                                }
                                                <br />
                                                <Row className="signup-row">
                                                    <Col>
                                                        <button className="custom-button outline"
                                                            onClick={() => { open(selectedDeliveryMode, selectedStore, addressId) }}>
                                                            {t("create.cart")}
                                                        </button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        open: (deliveryMode, storeId, addressId) => dispatch(actionCreators.open(deliveryMode, storeId, addressId)),
    };
}

const mapStateToProps = state => {
    return {
        cart: state.cart.cart
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CartCreate));