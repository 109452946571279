import React from "react";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Days from "./Days/Days"
import Timeslots from "./Timeslots/Timeslots"
import { Translation } from 'react-i18next';
import * as actionCreators from "../../../redux/store/actions/index"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

class Disponibility extends React.Component {
  changePage = () => {
    this.props.saveCheckoutInfo({
      day: this.props.day,
      timeSlotId: this.props.timeSlotId
    })
    this.props.history.push("/checkout/data");
  }

  render() {
    const { cart, day, selectTimeslot, timeSlotId } = this.props;
    return (
      <Translation>
        {t =>
          <>
            <div className="container-fluid">
              <Col className="text-center disponibilityInfo">
                <span>{t("disponibility.title")}</span>
                <br /><br />
                <span style={{ fontSize: "15px" }}>{cart && cart.storeDescription}</span>
              </Col>
            </div>
            <div className="container-fluid">
              <Row className="justify-content-center">
                <Days selectDay={this.props.selectDay} cartId={cart && cart.id} />
              </Row>
            </div>
            <div className="mt-3 mt-md-2">
              {day && <Timeslots day={day} selectTimeslot={selectTimeslot} cartId={cart && cart.id} />}
            </div>
            <Row className="justify-content-center">
              <button
                onClick={this.changePage}
                className={`custom-button ${day && timeSlotId ? "" : "custom-button-disabled"} checkout-button outline mt-4`}
              >
                {t("next")}
              </button>
            </Row>
          </>
        }
      </Translation>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    saveCheckoutInfo: (data) => dispatch(actionCreators.saveCheckoutInfo(data))
  };
}

export default withRouter(connect(undefined, mapDispatchToProps)(Disponibility));