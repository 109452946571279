import config from "../../../config/config"
import { handleResponse } from "../../../utils/handleResponse";

export const stripeService = {
    stripeClienSecret, stripeBoarding
}

function stripeClienSecret() {
    return (
        fetch(`${config.baseUrl}/cli/pay/stripe/clientsecret`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            credentials: 'include'
        })
            .then(handleResponse)
    );
}

function stripeBoarding() {
    return (
        fetch(`${config.baseUrl}/cli/pay/stripe/boarding`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            credentials: 'include'
        })
            .then(handleResponse)
    );
}

