import React from 'react'
import { useForm } from 'react-hook-form'
import { Translation } from 'react-i18next';
import resetPassword from "./Service"
import toaster from "../../utils/toaster"
import { Container, Row, Col } from 'react-bootstrap';
import ValidateInput from '../../components/UI/Input/ValidateInput';

export default function PasswordReset(props) {

    const { register, handleSubmit, errors, watch } = useForm({
        mode: 'onBlur',
    });

    const onSubmit = data => {
        resetPassword(data.email)
            .then(response => {
                console.log(response);
                toaster.standard("Richiesta effettuata con successo.");
            }).catch((error) => {
                toaster.error(error.message);
            })
    }

    return (
        <Translation>
            {t =>
                <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off" className={`needs-validation ${errors ? "had-validation" : ""}`}>
                    <input autoComplete="false" name="hidden" type="text" style={{ display: 'none' }} />


                    <Container className="signup-wrapper">
                        <div className="signup-form">
                            <p className="signup-form-title">{t("passwordReset.title")}</p>
                            <div>
                                <Row className="signup-row">
                                    <Col md={10} className="form-group">
                                        <ValidateInput
                                            name="email"
                                            label="email"
                                            type="email"
                                            watch={watch("email")}
                                            register={register({
                                                required: "Campo obbligatorio",
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                    message: "Indirizzo email non valido"
                                                }
                                            })}
                                        />
                                        <div className="form-control-invalid">
                                            {errors.email && errors.email.message}
                                        </div>
                                        <small id="emailHelp" className="form-text text-muted">
                                            {t('passwordReset.help')}
                                        </small>
                                    </Col>
                                </Row>

                                <button type="submit" className="mt-3 smartlist-header-button outline">
                                    {t("passwordReset.send")}
                                </button>
                            </div>

                        </div>
                    </Container>
                </form>
            }
        </Translation>
    );

}
