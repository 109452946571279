import React from "react";
import { Translation } from 'react-i18next';
import OrderItem from "./OrderItem/OrderItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i18n from "../../../i18n"
import Popup from "../../../components/UI/Popup/Popup";
import config from "../../../config/config";

export default class Order extends React.Component {
    renderCartTotal = (item) => {
        let result;
        if (item && item.cart) {
            if (item.cart.netTotal && item.cart.total &&
                item.cart.netTotal !== item.cart.total) {
                result =
                    <>
                        <nobr><span className="text-strike">
                            {`${item.cart.total.toFixed(2)} \u20AC`}
                        </span></nobr >
                        <nobr><span className="ml-1">
                            {`${item.cart.netTotal.toFixed(2)} \u20AC`}
                        </span></nobr>
                    </>
            } else {
                result = `${item.cart.netTotal.toFixed(2)} \u20AC`
            }
            return result;
        }
    }

    renderOperatorCartTotal = (item) => {
        let result;
        if (item && item.operatorCart) {
            if (item.operatorCart.netTotal && item.operatorCart.total &&
                item.operatorCart.netTotal !== item.operatorCart.total) {
                result =
                    <>
                        <nobr><span className="text-strike">
                            {`${item.operatorCart.total.toFixed(2)} \u20AC`}
                        </span></nobr >
                        <nobr><span className="ml-1">
                            {`${item.operatorCart.netTotal.toFixed(2)} \u20AC`}
                        </span></nobr>
                    </>
            } else {
                result = `${item.operatorCart.netTotal.toFixed(2)} \u20AC`
            }
            return result;
        }
    }

    renderTotalToPay = (item) => {
        let result;
        if (item) {
            if (item.operatorCart && item.operatorCart.totalToPay) {
                result = `${item.operatorCart.totalToPay.toFixed(2)} \u20AC`
            } else {
                result = `${item.cart.totalToPay.toFixed(2)} \u20AC`
            }
            return result;
        }
    }

    renderAbortOrder = (order, abort) => {
        let result;
        (order.status === "TO_BE_PREPARED" || order.status === "PAYMENT_PENDING") ?
            result = (
                <Popup
                    action={() => abort(order.id)}
                    title={i18n.t("order.abort")}
                    content={i18n.t("order.abort.question")}
                    button={i18n.t("delete")}
                    trigger={
                        <span>
                            <button className="d-none d-lg-inline smartlist-header-button smartlist-header-button--secondary outline">
                                <div className="text">
                                    <span className="pr-0"><FontAwesomeIcon icon="trash-alt" /></span>
                                    <span>Elimina</span>
                                </div>
                            </button>
                            <button className="d-lg-none ml-3 button-clean outline primary-text">
                                <FontAwesomeIcon icon="trash-alt" style={{ width: "15px", height: "20px" }} />
                            </button>
                        </span>
                    }
                />
            ) : result = null;

        return result;
    }

    renderPayment = (order) => {
        let result;
        order.status === "PAYMENT_PENDING" ?
            result = (
                <span>
                    <button className="d-none d-lg-inline smartlist-header-button smartlist-header-button--secondary outline"
                    onClick={() => {
                        window.location.href = `${config.baseUrl}/cli/pay/xpay/nonce?orderCode=${order.code}`
                    }}>
                        <div className="text">
                            <span>Paga ora</span>
                        </div>
                    </button>
                    <button className="d-lg-none ml-3 button-clean outline primary-text">
                        <span>Paga ora</span>
                    </button>
                </span>
            ) : result = null;

        return result;
    }

    renderCopyOrder = (order, copy) => {
        return (
            <Popup
                action={() => copy(order.id)}
                title={i18n.t("order.copy")}
                content={i18n.t("order.copy.question")}
                button={i18n.t("copy")}
                trigger={
                    <span>
                        <button className="d-none d-lg-inline smartlist-header-button smartlist-header-button--secondary outline">
                            <div className="text">
                                <span className="pr-0"><FontAwesomeIcon icon="copy" /></span>
                                <span>Copia</span>
                            </div>
                        </button>
                        <button className="d-lg-none ml-3 button-clean outline primary-text">
                            <FontAwesomeIcon icon="copy" style={{ width: "15px", height: "20px" }} />
                        </button>
                    </span>
                }
            />
        );
    }

    render() {
        const { order, items, abort, copy } = this.props;
        return (
            <Translation>
                {t =>
                    <>
                        <div className="mt-3 pr-2" style={{ display: "flex", justifyContent: "space-between", alignContent: "center" }}>
                            <span className="signup-form-title pt-sm-0 pt-lg-2">
                                {order && order.cart.title}
                            </span>
                            <span>
                                {order && this.renderPayment(order)}
                                {order && this.renderCopyOrder(order, copy)}
                                {order && this.renderAbortOrder(order, abort)}
                            </span>
                        </div>
                        <div className="mb-5 ml-3">
                            <div className="cart-heading d-none d-sm-block mb-2">
                                <div className="row">
                                    <div style={{ width: "65px" }}>{t("products")}</div>
                                    <div className="col pl-0 pr-3">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-4 text-left px-0" />
                                                <div className="col-2 text-right px-0">
                                                    Prezzo<br />unitario
                                                </div>
                                                <div className="col-1"></div>
                                                <div className="col-2 text-center">{t("amount")}</div>
                                                <div className="col-2 text-right px-0">
                                                    {t("total")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {items.map((item) => (
                                <OrderItem key={item.id} item={item} />
                            ))}
                            <div className="pt-sm-2 pt-0"></div>

                            <div className="cart-product-details">

                                <div className="row checkout-summary-total pt-3">
                                    <div style={{ width: "65px" }}>
                                        <nobr>
                                            <span className="checkout-summary-total-text-info">Totale articoli ordinati</span>
                                        </nobr>
                                    </div>
                                    <div className="col pl-0 pr-3">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-2 col-sm-4 text-left px-0" />
                                                <div className="col-3 col-sm-3 text-center px-0" />
                                                <div className="col-3 col-sm-2 text-center px-0">
                                                    <span className="d-none d-sm-block cart-product-total">
                                                        {order && order.cart && order.cart.itemsCount}
                                                    </span>
                                                </div>
                                                <div className="col-sm-2 col-4 text-right px-0">
                                                    <span className="cart-product-total">
                                                        {this.renderCartTotal(order)}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {order && order.operatorCart && order.status &&
                                    order.status !== "TO_BE_PREPARED" && order.status !== "IN_PREPARATION" &&
                                    <div className="row pt-2">
                                        <div style={{ width: "65px" }}>
                                            <nobr>
                                                <span className="checkout-summary-total-text-info">Totale articoli preparati</span>
                                            </nobr>
                                        </div>
                                        <div className="col pl-0 pr-3">
                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col-2 col-sm-4 text-left px-0" />
                                                    <div className="col-3 col-sm-3 text-center px-0" />
                                                    <div className="col-3 col-sm-2 text-center px-0">
                                                        <span className="d-none d-sm-block cart-product-total">
                                                            {order && order.operatorCart && order.operatorCart.itemsCount}
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-2 col-4 text-right px-0">
                                                        <span className="cart-product-total">
                                                            {this.renderOperatorCartTotal(order)}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }


                                {order && order.cart && order.cart.fees && order.cart.fees.map(fee => (
                                    <div key={fee.description} className="row pt-2">
                                        <div style={{ width: "65px" }}>
                                            <nobr>
                                                <span className="checkout-summary-total-text-info">
                                                    {fee.description}
                                                </span>
                                            </nobr>
                                        </div>
                                        <div className="col pl-0 pr-3">
                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col-8 col-sm-9 text-center" />
                                                    <div className="col-sm-2 col-4 text-right px-0">
                                                        <span className="cart-product-total">{`${fee.value.toFixed(2)} \u20AC`}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                <div className="row pt-2">
                                    <div style={{ width: "65px" }}>
                                        <nobr>
                                            <span className="checkout-summary-total-text-info">
                                                Totale Spesa
                                            </span>
                                        </nobr>
                                    </div>
                                    <div className="col pl-0 pr-3">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-8 col-sm-9 text-center" />
                                                <div className="col-sm-2 col-4 text-right px-0">
                                                    <span className="cart-product-total">{this.renderTotalToPay(order)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {order && order.operatorCart && order.operatorCart.payed &&
                                    <>
                                        <div className="row pt-2">
                                            <div style={{ width: "65px" }}>
                                                <nobr>
                                                    <span className="checkout-summary-total-text-info">
                                                        Totale scontrino(*)
                                            </span>
                                                </nobr>
                                            </div>
                                            <div className="col pl-0 pr-3">
                                                <div className="container-fluid">
                                                    <div className="row">
                                                        <div className="col-8 col-sm-9 text-center" />
                                                        <div className="col-sm-2 col-4 text-right px-0">
                                                            <span className="cart-product-total">
                                                                {`${order.operatorCart.payed} \u20AC`}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ fontSize: "12px" }} className="row pt-1 ">
                                            (*) Comprende eventuali prodotti aggiunti alla spesa dopo la preparazione
                                        rilevabili nello scontrino rilasciato alla consegna.</div>
                                    </>
                                }
                            </div>
                        </div>
                    </>
                }
            </Translation>
        );
    }
}



