import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const counterInput = (props) => {
    const value = `${props.value} ${props.um}`
    return (
        <div className="product-detail-counter mx-auto" style={{ marginBottom: "19px" }}>
            <div>
                <button className="outline" onClick={props.decrement}>
                    <FontAwesomeIcon icon="minus" size="1x" />
                </button>
            </div>
            <div className="justify-content-center">
                <input className="text-center" style={{ maxWidth: 'unset', width: '100px' }} value={value} disabled></input>
            </div>
            <div>
                <button className="outline" onClick={props.increment}>
                    <FontAwesomeIcon icon="plus" size="1x" />
                </button>
            </div>
        </div>
    );
}


export default counterInput;