import React from 'react'

function TearmsAndConditions(props) {
    return (
        <>
            <div className="container mb-5">
                <div className="text-center mb-3">
                    <h4 style={{marginTop: "40px", fontFamily: "FuturaStd-Bold", fontSize: "23px"}}>
                        Le presenti condizioni generali di vendita regolano l’utilizzo da parte degli utenti del
                        servizio di spesa online del sito www.vivoacasatua.com (di seguito il “Sito”).
                    </h4>
                </div>
                    <p style={{marginTop: "10px", fontFamily: "FuturaStd-Book"}}>
                        Il presente sito è di proprietà di C.D. Gest s.r.l., con sede legale e amministrativa in Via
                        Unità D’Italia snc, Scalea (CS), iscritta presso il Registro delle Imprese di Cosenza, codice
                        fiscale e P. IVA 02241230784. Il Sito è concesso in uso a D.C. Services s.r.l. (di seguito la
                        “Società”), responsabile della vendita on-line, con sede legale in via Giuseppe Ziccarelli 77
                        Orsomarso (CS), e sede amministrativa in via Unità D’Italia snc Scalea (CS), iscritta presso il
                        Registro delle Imprese di Cosenza, codice fiscale e P. IVA 02933820785 . Il Sito consente agli
                        utenti di acquistare i prodotti consultabili sul sito medesimo che sono venduti al dettaglio nei
                        punti vendita ad insegna “Vivo”, di proprietà di D.C. Services s.r.l. . La Società osserva la
                        normativa in materia di contratti conclusi a distanza di cui agli art. 45 e seguenti del D. Lgs
                        206/2005 s.m.i..
                    </p>



                <p className="subtitle" style={{textAlign: "justify", marginTop: "20px"}}>
                    Ti invitiamo a leggere attentamente le condizioni generali di vendita (di seguito le "Condizioni")
                    sotto riportate, che costituiscono un accordo vincolante per l’utilizzo del Sito, ed a stamparle e/o
                    salvarle su altro supporto duraturo a tua scelta.
                    <ul className="subtitle mt-2">
                        <li className="mb-2 title" style={{textDecoration:"underline"}}>
                            CONDIZIONI GENERALI
                        </li>
                        <p>Tutte le modalità di vendita, consegna e pagamento sono elencate in dettaglio nelle varie
                            sezioni della voce "Domande frequenti" del Sito e sono da ritenersi parte integrante e
                            sostanziale delle presenti Condizioni. Le Condizioni applicabili ad ogni singolo ordine
                            saranno quelle esposte sul Sito al momento dell'ordine stesso. Le eventuali nuove Condizioni
                            saranno efficaci dal momento della pubblicazione sul Sito e si applicheranno alle sole
                            vendite concluse successivamente alla pubblicazione medesima.</p>
                        <li className="mb-2 title" style={{textDecoration:"underline"}}>
                            OFFERTA AL PUBBLICO
                        </li>
                        <p>Tutti i prodotti ed i prezzi indicati nel Sito costituiscono un'offerta al pubblico con le
                            limitazioni e le modalità contenute nel Sito stesso e nelle presenti Condizioni.
                        </p>
                        <li className="mb-2 title" style={{textDecoration:"underline"}}>
                            PRODOTTI
                        </li>
                        <p>
                            La veste grafica dei prodotti consegnati potrebbe non coincidere con quella rappresentata
                            sul Sito nel caso in cui il produttore abbia modificato la confezione e, quindi, per motivi
                            indipendenti dalla volontà della Società. Le foto sono puramente indicative e gli articoli
                            potrebbero subire delle variazioni rispetto alla presentazione a catalogo o rappresentare il
                            prodotto già cotto o preparato.
                        </p>
                        <li className="mb-2 title" style={{textDecoration:"underline"}}>
                            PRODOTTI A PESO VARIABILE
                        </li>
                        <p>I prodotti a peso variabile (ad es. frutta, verdura, carne, prodotti di gastronomia e
                            formaggi) vengono proposti nel Sito in misure e pesi indicativi. Il peso effettivo si
                            determina solamente al momento della preparazione del prodotto, e può variare fino al 20% in
                            più o in meno rispetto a quanto indicativamente proposto nel Sito. Per tale motivo, ove la
                            spesa comprenda uno o più di tali prodotti, il peso e l'importo determinato al momento
                            dell'ordine potranno differire dal peso e dall'importo effettivo calcolato al momento
                            dell'emissione dello scontrino. L'esatto importo della spesa verrà comunicato con il
                            documento di consegna e quindi addebitato al Cliente utilizzando il mezzo di pagamento da
                            quest’ultimo prescelto esclusivamente al momento della consegna della spesa.
                        </p>
                        <li className="mb-2 title" style={{textDecoration:"underline"}}>
                            INDICAZIONE DEL PAESE DI ORIGINE O DI ALLEVAMENTO/MACELLAZIONE DEI PRODOTTI
                        </li>
                        <p>Per alcune tipologie di prodotti (es. deperibili come frutta e verdura) al momento
                            dell'acquisto verrà indicato il Paese di origine del prodotto, mentre per le carni verrà
                            indicato il Paese in cui l’animale è stato allevato e macellato. Tuttavia, in considerazione
                            della tipologia dei prodotti e delle modalità di effettuazione dell'ordine - anticipato
                            rispetto alla consegna – per alcuni prodotti potranno essere indicate, nell’apposita
                            sezione, più provenienze sulla base della disponibilità effettiva dei prodotti al momento
                            della consegna dell’ordine. Il prezzo del prodotto rimarrà invariato indipendentemente dal
                            Paese di origine o di allevamento e macellazione.</p>
                        <li className="mb-2 title" style={{textDecoration:"underline"}}>
                            PREZZI E PROMOZIONI
                        </li>
                        <p>Alla spesa del Cliente si applicherà esclusivamente il prezzo (per unità di misura Euro/Kg
                            per i prodotti a peso variabile e per confezione per tutti gli altri prodotti) calcolato al
                            momento dell'ordine, senza alcuna considerazione di aumenti o diminuzioni di prezzo, anche
                            per promozioni, eventualmente intervenute successivamente o precedentemente conclusesi.
                            Tutti i prezzi sono comprensivi di IVA. I prezzi e le promozioni sono calcolati al momento
                            dell'ordine o al momento della modifica dello stesso e, pertanto, non possiamo garantire che
                            il prezzo dell'articolo, inserito nel carrello, rimanga invariato al momento della consegna.
                            Ad esempio, i prezzi possono variare perché alcuni sconti e/o promozioni sono validi solo
                            per un periodo di tempo limitato.</p>
                        <li className="mb-2 title" style={{textDecoration:"underline"}}>
                            ACCETTAZIONE DEGLI ORDINI
                        </li>
                        <p>L'ordine inviato dal Cliente sarà vincolante per la Società solamente se l'intera procedura
                            d'ordine sarà stata completata regolarmente ed in modo corretto senza alcuna evidenziazione
                            di messaggi di errore da parte del Sito. La Società non inserirà nella spesa prodotti non
                            espressamente ordinati dal Cliente. Per la Società potranno essere accettati solo ordini per
                            un importo di spesa, pari o superiore a € 30,00 (Euro trenta/00) usufruendo del servizio di
                            “RITIRO IN NEGOZIO”, pari o superiore a € 50,00 (Euro cinquanta/00) al netto delle spese di
                            consegna e di eventuali oneri accessori usufruendo del servizio di “CONSEGNA A DOMICILIO”.
                            Si precisa che le liste della spesa salvate dal Cliente verranno conservate per 13 mesi
                            successivi alla data di ultima modifica delle stesse e quindi automaticamente cancellate. In
                            caso di eccessive richieste da parte della clientela, la Società si riserva la facoltà di
                            limitare il numero di ordini effettuabili da ciascun cliente in un determinato periodo.</p>
                        <li className="mb-2 title" style={{textDecoration:"underline"}}>
                            CONFERMA DELL'ORDINE
                        </li>
                        <p>L’ordine si intende concluso quando, nella sua casella di posta elettronica, il Cliente
                            riceverà
                            conferma dell'ordine effettuato con il riepilogo dei prodotti, dei relativi prezzi, della
                            data ed
                            ora di consegna/ritiro e delle condizioni generali e particolari applicabili all'ordine
                            stesso.</p>
                        <li className="mb-2 title" style={{textDecoration:"underline"}}>
                            ANNULLAMENTO E MODIFICA DELL'ORDINE
                        </li>
                        <p>Fatto salvo quanto previsto dall’art. 15, il Cliente potrà annullare l'intero ordine o
                            modificarlo (esclusivamente per quanto riguarda il giorno e la fascia oraria di consegna)
                            fino quando non verrà visualizzato lo stato “in preparazione”. Alle stesse condizioni, la
                            Società, a seguito di impreviste difficoltà logistiche ed organizzative, potrà annullare
                            l'ordine dandone comunicazione al Cliente via e-mail o telefonicamente oppure, previo
                            accordo con lo stesso, modificare giorno e/o ora di consegna. In caso di eccessive richieste
                            da parte della clientela, la Società si riserva la facoltà di limitare il numero delle
                            modifiche agli ordini già effettuati.</p>
                        <li className="mb-2 title" style={{textDecoration:"underline"}}>
                            MODALITA’ DI CONSEGNA  </li>
                            <ul>
                                <li className="mb-2">CONSEGNA A DOMICILIO</li>
                                <p>La Società consegna la spesa solamente nelle zone specificate nel Sito. La spesa
                                    verrà consegnata nella fascia oraria della giornata prescelta. <br/>
                                    Il personale si limiterà a consegnare la merce sul pianerottolo davanti alla porta
                                    d'ingresso dell'abitazione. Per ragioni organizzative e di efficienza del servizio,
                                    la spesa non potrà essere verificata o controllata dal Cliente al momento della
                                    consegna. Per qualsiasi anomalia riscontrata contattare il Servizio Clienti al
                                    Numero Verde 0985/271393. La Società non sarà responsabile per eventuali ritardi
                                    occorsi durante il trasporto. Parimenti la Società non potrà essere ritenuta
                                    responsabile per qualsiasi perdita, danno, errata o mancata consegna causati da
                                    eventi e/o cause di forza maggiore o caso fortuito, quali a titolo indicativo ma non
                                    esaustivo: calamità naturali, avverse condizioni atmosferiche (quali ad esempio
                                    intense nevicate), eventuali scioperi (dei propri o di altri dipendenti), incidenti
                                    a mezzi di trasporto, esplosioni, o ogni altra causa, simile o diversa. Il costo
                                    della consegna non è compreso nel prezzo dei prodotti. Per maggiori informazioni
                                    consulta la sezione "DOMANDE FREQUENTI" del Sito.
                                </p>
                                <li className="mb-2">
                                    RITIRO IN NEGOZIO
                                </li>
                                <p>La consegna della spesa avviene solo presso i punti di ritiro indicati sul sito. Il Cliente
                                    recatosi presso la sede di deve identificarsi attraverso l'APP oppure con la carta Vivo Card
                                    o
                                    il codice di conferma ordine fornito tramite e-mail. In ottemperanza all’obbligo introdotto
                                    dal
                                    D.L. 91/2017, i nuovi sacchetti compostabili contenenti la spesa avranno un costo di € 0,10
                                    cadauno. Non sono previsti costi aggiuntivi per il ritiro della spesa. Per maggiori
                                    informazioni
                                    consulta la sezione "DOMANDE FREQUENTI" del Sito.
                                </p>
                            </ul>
                    </ul>
                    <ul>
                        <li className="mb-2 title" style={{textDecoration:"underline"}}>
                            PRODOTTI NON DISPONIBILI E LIMITAZIONI ALL'ACQUISTO
                        </li>
                        <p>La Società non sarà in alcun modo responsabile per la temporanea o definitiva indisponibilità
                            di uno o più prodotti. Nel caso di indisponibilità, anche temporanea, dei prodotti
                            richiesti, la Società si impegna a non addebitare al Cliente il prezzo corrispondente. Nel
                            sito sono evidenziati i casi in cui valgano limitazioni all'acquisto di singoli prodotti.
                        </p>
                        <li className="mb-2 title" style={{textDecoration:"underline"}}>
                            MANCATA CONSEGNA
                        </li>
                        <p>Per la consegna è necessaria la presenza del Cliente o di un suo incaricato all'indirizzo ed
                            all'orario indicato nell'ordine. Se il Cliente (o la persona incaricata) non è presente,
                            oppure non dispone di carta di credito/bancomat validi o sia nell'impossibilità di pagare
                            con carta di credito/bancomat (ad es. per il superamento dei massimali di spesa), l'ordine
                            si intenderà annullato; sarà inoltre facoltà della Società escludere il Cliente dal servizio
                            o subordinare la prestazione del servizio al pagamento on line.
                        </p>
                        <li className="mb-2 title" style={{textDecoration:"underline"}}>
                            PAGAMENTO
                        </li>
                        <p>
                            L'acquisto può essere pagato dal Cliente in contanti o online attraverso:
                        </p>
                        <p>
                            carte di credito, debito e prepagate abilitate ai pagamenti online dei circuiti MasterCard,
                            Visa, American Express, Maestro;
                        </p>
                        <p>Al momento della conferma dell’ordine di acquisto, viene richiesta all’emittente carta, una
                            pre-autorizzazione (blocco del plafond o disponibilità della carta), di un importo pari al
                            totale dell’ordine maggiorato del 20% per i soli articoli soggetti a peso variabile (vedere
                            articolo 4). Al momento della consegna della spesa viene inviata una richiesta di addebito,
                            per l’importo effettivo della spesa. L’importo della pre-autorizzazione (blocco del plafond
                            o disponibilità della carta) verrà cancellato dall’emittente carta del cliente, con tempi
                            variabili a seconda del singolo emittente.
                        </p>
                        <li className="mb-2 title" style={{textDecoration:"underline"}}>
                            FATTURA
                        </li>
                        <p>L'emissione della fattura non è obbligatoria, se non è richiesta dal Cliente non oltre il
                            momento di effettuazione dell'ordine come indicato nel D.P.R. del 26/10/1972 n. 633 -
                            art.22.</p>
                        <li className="mb-2 title" style={{textDecoration:"underline"}}>
                            DIRITTO DI RECESSO
                        </li>
                        <p>I diritti del Cliente sono tutelati dal D. Lgs 206/2005 s.m.i.. Il Cliente ha, pertanto, il
                            diritto di recedere dal contratto, anche parzialmente, senza spiegazioni e senza aggravio di
                            spese, a condizione che il recesso sia comunicato via e-mail all’indirizzo
                            servizioclienti@vivoacasatua.com entro 14 giorni lavorativi dal giorno del ricevimento dei
                            prodotti. Tale comunicazione dovrà specificare la volontà di recedere dall'acquisto ed il
                            prodotto o i prodotti per i quali si intende esercitare il diritto di recesso, allegando
                            copia dello scontrino.</p>
                        <p>
                            Il Cliente dovrà quindi restituire a proprie spese il prodotto secondo le seguenti modalità:
                        </p>
                        <p>- nelle zone in cui Vivo è presente (per conoscerle consulta la sezione "Punti Vendita" del
                            Sito), occorre recarsi presso un qualsiasi punto vendita Vivo entro 14 giorni lavorativi dal
                            ricevimento del prodotto;</p>
                        <p>- nelle zone in cui Vivo non è presente, occorre inviare il prodotto, entro 14 giorni
                            lavorativi dal ricevimento, a D.C. Services S.r.l., via Unità D’Italia snc, Scalea (CS). Se
                            il recesso sarà stato esercitato conformemente alle modalità descritte, la Società
                            provvederà a rimborsare la somma versata per l'acquisto del prodotto entro 14 giorni dalla
                            data in cui la Società è venuta a conoscenza dell'esercizio del diritto di recesso da parte
                            del Cliente. L'importo rimborsato sarà al netto delle spese di consegna. Il Cliente sarà
                            responsabile della diminuzione di valore del bene restituito risultante da una manipolazione
                            diversa da quella necessaria per stabilire la natura, le caratteristiche e il funzionamento
                            del bene stesso.</p>
                        <p>Fermo quanto sopra, il diritto di recesso non è consentito in tutti i casi previsti
                            dall’art.59 del D.Lgs 206/2005 e, in via esemplificativa ma non esaustiva, nel caso di:</p>
                        <ul>
                            <li>acquisto di prodotti confezionati sigillati, aperti dal Cliente;</li>
                            <li>di beni confezionati su misura o personalizzati (ad es. prodotti di gastronomia);
                            </li>
                            <li>di beni che per loro natura non possono essere rispediti o rischiano di deteriorarsi
                                o alterarsi rapidamente (ad es. i prodotti alimentari freschi e deperibili come i
                                prodotti di gastronomia);
                            </li>
                            <li>prodotti virtuali (a titolo esemplificativo e non esaustivo: cofanetti regalo, gift
                                card, ricariche virtuali…)
                            </li>
                            <li>carte prepagate e/o ricariche telefoniche che siano state attivate dal Cliente;</li>
                        </ul>
                        <li className="mb-2 mt-2 title" style={{textDecoration:"underline"}}>
                            SOSPENSIONE DEL SERVIZIO
                        </li>
                        <p>In caso di gravi inadempimenti da parte del Cliente (a titolo esemplificativo ma non
                            esaustivo: mancato pagamento della spesa effettuata, assenza ripetuta del Cliente al momento
                            della consegna della spesa, non corretto utilizzo di modalità di effettuazione della spesa),
                            la Società, previa comunicazione scritta al Cliente, si riserva la facoltà di sospendere il
                            servizio.</p>
                        <li className="mb-2 title" style={{textDecoration:"underline"}}>
                            RESPONSABILITÀ
                        </li>
                        <p>La Società garantisce il rispetto della catena del freddo e, in generale delle norme di
                            qualità relative ai prodotti (in particolare alimentari) esclusivamente fino al momento
                            della consegna presso il luogo indicato dal Cliente; è esclusa ogni responsabilità relativa
                            al cattivo stato dei prodotti dovuta ad impropria conservazione successiva al momento della
                            consegna. La Società declina ogni responsabilità per danni diretti o indiretti di qualunque
                            natura essi siano o sotto qualunque forma si manifestino, conseguenti all'utilizzo del Sito
                            e/o delle notizie, foto ed informazioni ivi contenute e qualora la consegna avvenga, su
                            richiesta del Cliente, all'interno dell'abitazione. La Società non si assume alcuna
                            responsabilità sulle informazioni fornite/acquisite dai singoli produttori o
                            distributori. </p>
                        <li className="mb-2 title" style={{textDecoration:"underline"}}>
                            VIVO CARD
                        </li>
                        <p>La spesa del Cliente partecipa alla raccolta punti Vivo Collection conformemente a quanto
                            indicato nel Regolamento Vivo Collection, a condizione che il Cliente fornisca l'esatto
                            numero di carta. </p>
                        <li className="mb-2 title" style={{textDecoration:"underline"}}>
                            PRIVACY
                        </li>
                        <p>La Società procederà al trattamento dei dati personali del Cliente nel rispetto della
                            normativa in materia di privacy come precisato in dettaglio nell'informativa rilasciata al
                            momento di richiesta della Vivo Card.
                        </p>
                        <li className="mb-2 title" style={{textDecoration:"underline"}}>
                            FORO COMPETENTE
                        </li>
                        <p>Ogni e qualsiasi controversia che dovesse insorgere in relazione all'applicazione,
                            interpretazione ed esecuzione delle presenti Condizioni, sarà inderogabilmente devoluta al
                            giudice del luogo di residenza o di domicilio del consumatore, se ubicati nel territorio
                            dello Stato.
                        </p>
                        <li className="mb-2 title" style={{textDecoration:"underline"}}>
                            GARANZIA LEGALE DI CONFORMITÀ
                        </li>
                        <p>La Società riconosce su tutti i prodotti acquistati la garanzia legale di conformità. Per
                            maggiori informazioni consulta la sezione "Garanzia Legale di conformità" del Sito.

                        </p>
                    </ul>
                </p>
            </div>
        </>

    )
        ;
}

export default TearmsAndConditions;
