import React from "react"
import { useForm } from 'react-hook-form'
import { Translation } from 'react-i18next';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from "react-bootstrap/Container"
import { connect } from "react-redux"
import * as actionCreators from "../../../redux/store/actions/index"
import ValidateInput from "../../../components/UI/Input/ValidateInput";
import Accepts from "../../../components/Accepts/Accepts";

function Profile(props) {

    const { register, handleSubmit, errors, watch } = useForm({
        mode: 'onBlur',
    });

    const onSubmit = data => {
        props.updateUser(data);
    }

    // console.log(watch())
    // console.log(watch('errors'))

    return (
        <Translation>
            {t =>

                <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off" className={`needs-validation ${errors ? "had-validation" : ""}`}>
                    <input autoComplete="false" name="hidden" type="text" style={{ display: 'none' }} />

                    <Container className="signup-wrapper">
                        <div className="signup-form">
                            <p className="signup-form-title">{t('signup.title')}</p>
                            <div>
                                <Row className="signup-row">
                                    <Col md={6} className="form-group">
                                        <ValidateInput
                                            name="firstName"
                                            label="firstName"
                                            type="text"
                                            value={props.user && props.user.firstName}
                                            watch={watch("firstName")}
                                            register={register({
                                                required: "Campo obbligatorio"
                                            })}
                                        />
                                        <div className="form-control-invalid">
                                            {errors.firstName && errors.firstName.message}
                                        </div>
                                    </Col>
                                    <Col md={6} className="form-group">
                                        <ValidateInput
                                            name="lastName"
                                            label="lastName"
                                            type="text"
                                            value={props.user && props.user.lastName}
                                            watch={watch("lastName")}
                                            register={register({
                                                required: "Campo obbligatorio"
                                            })}
                                        />
                                        <div className="form-control-invalid">
                                            {errors.lastName && errors.lastName.message}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="signup-row">
                                    {/* <Col md={6} className="form-group">
                                        <ValidateInput
                                            name="birthDate"
                                            label="birthDate"
                                            type="date"
                                            value={props.user && props.user.birthDate &&
                                                format(parseISO(props.user.birthDate), "yyyy-MM-dd")}
                                            watch={watch("birthDate")}
                                            register={register({
                                                required: "Campo obbligatorio",
                                                pattern: {
                                                    value: /^\d{4}-\d{2}-\d{2}$/i,
                                                    message: "Data non valida"
                                                }
                                            })}
                                        />
                                        <div className="form-control-invalid">
                                            {errors.birthDate && errors.birthDate.message}
                                        </div>
                                    </Col> */}
                                     <Col md={6} className="form-group">
                                        <ValidateInput
                                            name="ssnCode"
                                            label="ssnCode"
                                            type="text"
                                            value={props.user && props.user.ssnCode}
                                            watch={watch("ssnCode")}
                                            register={register({
                                                required: "Campo obbligatorio",
                                            })}
                                        />
                                        <div className="form-control-invalid">
                                            {errors.ssnCode && errors.ssnCode.message}
                                        </div>
                                    </Col>
                                    <Col md={6} className="form-group">
                                        <ValidateInput
                                            name="mobilePhone"
                                            label="mobilePhone"
                                            type="text"
                                            value={props.user && props.user.mobilePhone}
                                            watch={watch("mobilePhone")}
                                            register={register({
                                                pattern: {
                                                    value: /^\d{10}$/i,
                                                    message: "Numero di cellulare non valido"
                                                }
                                            })}
                                        />
                                        <div className="form-control-invalid">
                                            {errors.mobilePhone && errors.mobilePhone.message}
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            <p className="signup-form-title mt-3">{t('signup.card')}</p>

                            <div>
                                <Row className="signup-row">
                                    <Col md={6} className="form-group">
                                        <ValidateInput
                                            name="profileCard"
                                            label="profileCard"
                                            type="number"
                                            value={props.user && props.user.cards && props.user.cards[0] && props.user.cards[0].code}
                                            watch={watch("profileCard")}
                                            register={register}
                                        />
                                        <div className="form-control-invalid">
                                            {errors.code && errors.code.message}
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            <p className="signup-form-title mt-3">{t('signup.privacy')}</p>

                            <Accepts errors={errors} register={register} user={props.user} disabled={true} />

                            <div className="mt-3 mb-3">
                                <button className="custom-button outline" type="submit">
                                    {t("save")}
                                </button>
                            </div>
                        </div>
                    </Container>

                </form>
            }
        </Translation >
    );
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateUser: (data) => dispatch(actionCreators.updateUser(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);