import React from "react";
import { Route, Switch, Link } from 'react-router-dom';
import { Translation } from 'react-i18next';
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import ProfileAddresses from "./ProfileAddresses/ProfileAddresses";
import BillingAddresses from "./ProfileAddresses/BillingAddresses";
import Profile from "./Profile/Profile";

import "./profile.styles.scss";

class ProfileContainer extends React.Component {
    render() {
        const { user } = this.props;
        return (
            <Translation>
                {t =>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-4 col-sm-12 profile-menu">
                                <div className="info">
                                    Le tue informazioni
                                </div>
                                <div className="name">
                                    {user &&
                                        <span>
                                            {user.firstName} {user.lastName}
                                        </span>}
                                </div>
                                <div className={`profile-link 
                                    ${this.props.history.location.pathname === "/profile" ? "profile-link-active" : ""}`}>
                                    <Link className="link" to="/profile">
                                        I tuoi dati
                                    </Link>
                                </div>
                                <div className={`profile-link 
                                    ${this.props.history.location.pathname === "/profile/addresses" ? "profile-link-active" : ""}`}>
                                    <Link className="link" to="/profile/addresses">
                                        Indirizzi di spedizione
                                    </Link>
                                </div>
                                <div className={`profile-link 
                                    ${this.props.history.location.pathname === "/profile/addresses" ? "profile-link-active" : ""}`}>
                                    <Link className="link" to="/profile/billing">
                                        Indirizzi di fatturazione
                                    </Link>
                                </div>
                            </div>
                            <div className="col-xl-8 col-sm-12">
                                <Switch>
                                    <Route exact path='/profile' render={() => <Profile />} />
                                    <Route exact path='/profile/addresses' render={() => <ProfileAddresses />} />
                                    <Route exact path='/profile/billing' render={() => <BillingAddresses />} />
                                </Switch>
                            </div>
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
    };
}

export default withRouter(connect(mapStateToProps)(ProfileContainer));


