import React from 'react'

function Privacy(props) {
    return (
        <>
            <div className="container">
                <div className="text-center">
                    <h4 style={{ marginTop: "40px", fontFamily: "FuturaStd-Bold", fontSize: "23px" }}>
                        INFORMATIVA SUL TRATTAMENTO DEI DATI PERSONALI (CLIENTI)
                    </h4>
                    <h7 style={{ marginTop: "10px", fontFamily: "FuturaStd-Book" }}>
                        ai sensi dell’art. 13 del Regolamento UE n. 2016/679 e del D. Lgs. 196/2003 e s.m.i.
                    </h7>
                </div>


                <p className="subtitle" style={{ textAlign: "justify", marginTop: "20px" }}>
                    I dati personali dell'utente sono utilizzati da D.C. SERVICES S.r.l. nel rispetto dei principi di
                    protezione dei dati personali stabiliti dal Regolamento GDPR 2016/679 e dal D.Lgs. 196/2003 e s.m.i..<br />
                    <span style={{ textDecoration: "underline" }} className="title">MODALITÀ E FINALITÀ DEL TRATTAMENTO DATI</span> <br />
                    La informiamo che i dati verranno trattati nel rispetto di quanto previsto dall’art. 32 del GDPR 2016/679 e dal D.Lgs.
                    196/2003 in materia di misure di sicurezza, ad opera di soggetti appositamente incaricati e in ottemperanza a
                    quanto previsto dagli art. 29 GDPR 2016/679 con il supporto dei seguenti mezzi:
                    <ul className="subtitle">
                        <li className="mb-2">
                            Mista - elettronica e cartacea <br /> con le seguenti finalità:
                        </li>
                        <li className="mb-2">
                            Attività promozionali
                        </li>
                        <li className="mb-2">
                            Fidelizzazione cliente con vantaggi connessi alla carta
                        </li>
                        <li className="mb-2">
                            Newsletter
                        </li>
                        <li className="mb-2">
                            Profilazione a fini promozionali
                        </li>
                    </ul>
                </p>
                <p className="subtitle" style={{ textAlign: "justify" }}>
                    <span style={{ textDecoration: "underline" }} className="title">BASE GIURIDICA</span> <br />
                    Il conferimento dei dati è obbligatorio per tutto quanto è richiesto dagli obblighi legali e contrattuali e pertanto l'eventuale rifiuto a fornirli in tutto o in parte può dar luogo all'impossibilità di fornire i servizi richiesti.
                    La società tratta i dati facoltativi degli utenti in base al consenso, ossia mediante l’approvazione esplicita della presente policy privacy e in relazione alle modalità e finalità di seguito descritte.
                </p>
                <p className="subtitle" style={{ textAlign: "justify" }}>
                    <span style={{ textDecoration: "underline" }} className="title">CATEGORIE DI DESTINATARI</span> <br />
                    Ferme restando le comunicazioni eseguite in adempimento di obblighi di legge e contrattuali, tutti i dati raccolti ed elaborati potranno essere comunicati esclusivamente per le finalità sopra specificate alle seguenti categorie di interessati:
                    <ul className="subtitle">
                        <li className="mb-2">
                            Clienti ed utenti;
                        </li>
                        <li className="mb-2">
                            Società e imprese;
                        </li>
                    </ul>
                </p>
                <p className="subtitle" style={{ textAlign: "justify" }}>
                    <span style={{ textDecoration: "underline" }} className="title">AMBITO DI COMUNICAZIONE E DIFFUSIONE</span> <br />
                    Informiamo che i dati raccolti non saranno mai diffusi e non saranno oggetto di comunicazione senza Suo esplicito consenso, salvo le comunicazioni necessarie che possono comportare il trasferimento di dati ad enti pubblici, a consulenti o ad altri soggetti per l’adempimento degli obblighi di legge.
                </p>
                <p className="subtitle" style={{ textAlign: "justify" }}>
                    <span style={{ textDecoration: "underline" }} className="title">TRASFERIMENTO DATI PERSONALI PRESSO PAESI TERZI</span> <br />
                    I suoi dati non saranno trasferiti né in Stati membri dell’Unione Europea né in Paesi terzi non appartenenti all’Unione Europea.
                </p>
                <p className="subtitle" style={{ textAlign: "justify" }}>
                    <span style={{ textDecoration: "underline" }} className="title">STRUTTURE INFORMATICHE</span> <br />
                    <table style={{ width: "100%", border: "1px solid" }}>
                        <tr>
                            <td style={{ background: "#cfcfcf", border: "1px solid", fontFamily: "FuturaStd-Bold", width: "50%" }}>Denominazione attività</td>
                            <td style={{ border: "1px solid", fontFamily: "FuturaStd-Bold" }}>Fidelity Card</td>
                        </tr>
                        <tr>
                            <td style={{ background: "#cfcfcf", border: "1px solid", fontFamily: "FuturaStd-Bold" }}>Strutture informatiche</td>
                            <td style={{ border: "1px solid" }}>AS 400 CIS CEDI</td>
                        </tr>
                        <tr>
                            <td style={{ background: "#cfcfcf", border: "1px solid", fontFamily: "FuturaStd-Bold" }}>Tipo Struttura</td>
                            <td style={{ border: "1px solid" }}>Interna</td>
                        </tr>
                        <tr>
                            <td style={{ background: "#cfcfcf", border: "1px solid", fontFamily: "FuturaStd-Bold" }}>Sede</td>
                            <td style={{ border: "1px solid" }}>SEDE AMMINISTRATIVA (SCALEA)</td>
                        </tr>
                    </table>
                </p>
                <p className="subtitle" style={{ textAlign: "justify" }}>
                    <span style={{ textDecoration: "underline" }} className="title">PERIODO DI CONSERVAZIONE</span> <br />
                    I dati obbligatori ai fini contrattuali e contabili sono conservati per il tempo necessario allo svolgimento del rapporto commerciale e contabile.
                    I dati di chi non acquista o usufruisce di prodotti/servizi, pur avendo avuto un precedente contatto con dei rappresentanti dell’azienda, saranno immediatamente cancellati o trattati in forma anonima, ove la loro conservazione non risulti altrimenti giustificata, salvo che sia stato acquisito validamente il consenso informato degli interessati relativo ad una successiva attività di promozione commerciale o ricerca di mercato.
                    Il periodo di conservazione dei dati è: I dati saranno trattati per tutto il tempo necessario allo svolgimento del rapporto commerciale in essere e per i successivi dieci anni dalla data di acquisizione degli stessi.
                </p>
                <p className="subtitle" style={{ textAlign: "justify" }}>
                    <span style={{ textDecoration: "underline" }} className="title">DIRITTI DELL’INTERESSATO</span> <br />
                    Ai sensi degli artt. 15-22 Regolamento europeo 679/2016, l'interessato può, secondo le modalità e nei limiti previsti dalla vigente normativa, esercitare il diritto di: <br />
                    1. <span style={{ textDecoration: "underline" }}>accesso</span>: richiedere la conferma dell'esistenza o meno di un trattamento di dati personali che lo riguardano. Nel caso in cui sia in corso il trattamento, l’interessato ha diritto ad ottenere le seguenti informazioni:
                    <ul className="subtitle">
                        <li className="mb-2">
                            la logica, le modalità e le finalità del trattamento;
                        </li>
                        <li className="mb-2">
                            le categorie di dati trattati e la loro origine; gli estremi identificativi del titolare e, se presenti, dei responsabili, dei rappresentanti;
                        </li>
                        <li className="mb-2">
                            l’indicazione dei soggetti o delle categorie di soggetti ai quali i dati personali possono essere comunicati o che possono venirne a conoscenza;
                        </li>
                        <li className="mb-2">
                            quando possibile, il periodo di conservazione dei dati personali oppure, se non è possibile, i criteri utilizzati per determinare tale periodo.
                        </li>
                    </ul>
                    2. <span style={{ textDecoration: "underline" }}>rettifica e cancellazione dei dati</span>:  richiederne l'aggiornamento, la rettifica, l'integrazione, la cancellazione, la trasformazione in forma anonima, il blocco dei dati trattati in violazione di legge, ivi compresi quelli non più necessari al perseguimento degli scopi per i quali sono stati raccolti; <br />
                    3. <span style={{ textDecoration: "underline" }}>limitazione e/o opposizione al trattamento dei dati,</span> compresa l’opposizione ad un processo decisionale automatizzato relativo alle persone fisiche (inclusa la profilazione); <br />
                    4. <span style={{ textDecoration: "underline" }}>portabilità dei dati:</span> nei casi di trattamento basato su consenso, ricevere i propri dati forniti al titolare, in forma strutturata e leggibile da un elaboratore di dati e in un formato comunemente usato da un dispositivo elettronico;<br />
                    5. <span style={{ textDecoration: "underline" }}>revoca del consenso</span> in qualsiasi momento, senza pregiudicare la liceità del trattamento basata sul consenso prestato prima della revoca;<br />
                    6. <span style={{ textDecoration: "underline" }}>presentare reclamo all’Autorità di controllo,</span> qualora si ritenga che il trattamento violi la normativa.<br />
                </p>
                <p className="subtitle" style={{ textAlign: "justify" }}>
                    <span style={{ textDecoration: "underline" }} className="title">TITOLARE DEL TRATTAMENTO</span> dei Suoi dati personali per D.C. SERVICES S.r.l., p.iva 02933820785, c.f. 02933820785
                    <ul className="subtitle">
                        <li className="mb-2">
                            Email: dcservices@pec.it
                        </li>
                        <li className="mb-2">
                            PEC: dcservices@pec.it <br />
                            è il legale rappresentante DE CAPRIO ALFREDO, c.f. DCPLRD79A19G129I
                        </li>
                        <li className="mb-2">
                            Email: alfredo.decaprio@cdgest.com
                        </li>
                        <li className="mb-2">
                            Telefono: 3358323677
                        </li>
                    </ul>
                </p>

                <p className="subtitle" style={{ textAlign: "justify" }}>
                    <span style={{ textDecoration: "underline" }} className="title">DATA PROTECTION OFFICIER - D.P.O.</span>
                    è il/la Sig./Sig.ra MANCO GUENDALINA, c.f. MNCGDL84E42F839T, che può essere contattato al seguente indirizzo email: guendalina.m@cdgest.com.
                </p>

                <div className="text-center">
                    <h4 style={{ marginTop: "40px", fontFamily: "FuturaStd-Bold", fontSize: "23px" }}>
                        INFORMATIVA WEB
                    </h4>
                </div>
                <p className="subtitle" style={{ textAlign: "justify" }}>
                    <span style={{ color: "#5e5e5e", textTransform: "unset" }} className="title">Cosa sono i cookies</span> <br />
                    Un "cookie" è un file di testo salvato nel computer dell'utente nel momento in cui questo accede ad un sito web con lo scopo di fornire informazioni ogni volta che l'utente ritorna sullo stesso sito. E' una sorta di promemoria della pagina internet visitata. Con il cookie, il server web invia informazioni al browser
                    dell'utente (Internet Explorer, Mozilla Firefox, Google Chrome, ecc.) memorizzate sul computer di quest'ultimo, e saranno rilette ed aggiornate ogni qual volta l'utente ritornerà sul sito. In questo modo il sito Web può adattarsi automaticamente all'utente. Nel corso della navigazione l'utente potrebbe ricevere sul suo terminale anche cookie di siti diversi (cookies di "terze parti"), impostati direttamente da gestori di detti siti web e utilizzati per le finalità e secondo le modalità da questi definiti.
                    In funzione della loro durata, si distinguono in cookie di sessione (ossia quelli temporanei e cancellati automaticamente dal terminale al termine della sessione di navigazione, chiudendo il browser) ed in cookie persistenti (ossia quelli che restano memorizzati sul terminale fino alla loro scadenza o cancellazione da parte dell'utente). In base alla funzione e alla finalità di utilizzo, i cookie possono suddividersi in cookie tecnici e cookie di profilazione.
                </p>
                <p className="subtitle" style={{ textAlign: "justify" }}>
                    <span style={{ color: "#5e5e5e", textTransform: "unset" }} className="title">Cookie tecnici</span> <br />
                    Alcuni cookie sono usati per eseguire autenticazioni informatiche, monitoraggio di sessioni e memorizzazione di informazioni specifiche sugli utenti che accedono ad una pagina web. Questi cookie, cosiddetti tecnici, sono spesso utili per consentire di navigare in un sito web e utilizzarne tutte le funzionalità. I cookie tecnici sono quelli il cui utilizzo non richiede il consenso dell'utente.
                    A questa categoria appartengono anche gli analytics cookie. Si tratta di cookie che raccolgono informazioni circa l'utilizzo che un utente fa di un sito web e che consentono di migliorarne il funzionamento. Ad esempio gli analytics cookie mostrano quali sono le pagine più frequentemente visitate, consentono di verificare quali sono gli schemi ricorrenti d'utilizzo di un sito web e aiutano a comprendere ogni difficoltà che l'utente incontra nell'utilizzo.
                </p>
                <p className="subtitle" style={{ textAlign: "justify" }}>
                    <span style={{ color: "#5e5e5e", textTransform: "unset" }} className="title">Cookie di profilazione</span> <br />
                    Altri cookie possono invece essere utilizzati per monitorare e profilare gli utenti durante la navigazione, studiare i loro movimenti e abitudini di consultazione del web o di consumo (cosa comprano, cosa leggono, ecc.), anche allo scopo di inviare pubblicità di servizi mirati e personalizzati. Si parla in questo caso di cookie di profilazione. L'utilizzo di detti cookie necessita dell'acquisizione preventiva del libero consenso informato dell'utente ai sensi dell'art. 7 del Regolamento UE 2016/679.
                </p>
                <p className="subtitle" style={{ textAlign: "justify" }}>
                    <span style={{ color: "#5e5e5e", textTransform: "unset" }} className="title">Cookie di terze parti</span> <br />
                    Può accadere anche che una pagina web contenga cookie provenienti da altri siti e contenuti in vari elementi ospitati sulla pagina stessa, come ad esempio banner pubblicitari, immagini, video, mappe o specifici link a pagine web di altri domini che risiedono su server diversi da quello sul quale si trova la pagina richiesta. In altre parole, questi cookie sono impostati direttamente da gestori di siti web o server diversi da questo sito web. Parliamo, in questi casi, dei cosiddetti cookie terze parti, che di solito sono utilizzati a fini di profilazione. L'utilizzo di questi cookie necessita dell'acquisizione preventiva del libero consenso informato dell'utente.
                </p>
                <p className="subtitle" style={{ textAlign: "justify" }}>
                    <span style={{ color: "#5e5e5e", textTransform: "unset" }} className="title">Tipologie di cookie utilizzati dal nostro sito</span> <br />
                    Le funzioni principali dei cookies installati da www.supermercativivocalabria.com sono tecniche, vengono utilizzati per migliorare costantemente il Servizio, per l'autenticazione, per fini statistici, come il conteggio delle visite al sito. L'utilizzo di cookies è dunque strettamente finalizzato a facilitare le funzioni del server di durante la navigazione del Servizio.
                    Il sito consente inoltre l'invio dei seguenti cookie di terze parti. Questi cookie non sono strumenti di nostra titolarità, per maggiori informazioni, pertanto, è possibile accedere all'informativa ed ai moduli di acquisizione del consenso delle terze parti, cliccando sui link riportati.
                    Per migliorare il sito web e comprendere quali parti o elementi siano maggiormente apprezzati dagli utenti, sono utilizzati come strumento di analisi anonima e aggregata, i cookie di terza parte di Google Analytics. Questi cookie non sono strumenti di nostra titolarità, per maggiori informazioni, pertanto, è possibile consultare l'informativa fornita da Google.
                    Le pagine del sito web incorporano al loro interno alcuni widget e pulsanti di condivisione di Facebook e Google Plus, per permettere all'utente di condividere i contenuti del sito web sui propri canali social, e di interagire con i nostri canali. Questi cookie non sono strumenti di nostra titolarità, ma sono creati rispettivamente da Facebook e Google nel momento in cui si utilizza il rispettivo widget o pulsante di condivisione. Per saperne di più visitare le seguenti pagine informative: Facebook e Google.
                    Alcune pagine web incorporano al loro interno dei contenuti video di YouTube. Visitando una pagina contenente un video, o facendo clic per visualizzare il video, potrebbero essere richiamati cookie provenienti da YouTube. Questi cookie non sono strumenti di nostra titolarità. Per saperne di più visitare la pagina informativa di Google.
                    Il sito internet potrebbe utilizzare i programmi di Facebook ADS gestito da Facebook Inc., Google Adwords e la tecnologia Google Remarketing, gestiti da Google Inc. Anche la funzione monitoraggio delle conversioni di Facebook ADS e AdWords utilizza i cookie per aiutarci a tenere traccia delle vendite e di altre conversioni.
                </p>
                <p className="subtitle" style={{ textAlign: "justify" }}>
                    <span style={{ color: "#5e5e5e", textTransform: "unset" }} className="title">Gestione dei cookie</span> <br />
                    L'utente può gestire le proprie preferenze relative ai cookie attraverso le funzionalità presenti nei comuni browser che consentono di cancellare/rimuovere i cookie (tutti o alcuni) o di cambiare le impostazioni del browser stesso in modo da bloccare l'invio dei cookie o di limitarlo a siti specifici (rispetto ad altri).
                    Pertanto è possibile negare l'utilizzo dei cookie, seguendo la procedura di disabilitazione prevista dal proprio browser. Di seguito si riportano le modalità proposte dai principali browser: <span className="primary-color">Microsoft Windows Explorer, Mozilla Firefox, Google Chrome, Apple Safari</span>, Google Analytics scaricando uno specificoplug- in del browser.
                    Per avere informazioni sui cookie archiviati sul proprio terminale e disattivarli singolarmente si rinvia al link:
                    <a href="http://www.youronlinechoices.com/it/le-tue-scelte" target="_blank" rel="noopener noreferrer">
                        http://www.youronlinechoices.com/it/le-tue-scelte
                    </a>
                </p>
                <p className="subtitle" style={{ textAlign: "justify" }}>
                    <span style={{ color: "#5e5e5e", textTransform: "unset" }} className="title">Plugin Social Network</span> <br />
                    Il sito www.supermercativivocalabria.com incorpora anche plugin e/o comandi per i social network, al fine di consentire una facile condivisione dei contenuti sui vostri social network preferiti. Tali plugin sono programmati in modo da non impostare alcun cookie all'accesso della pagina, per salvaguardare la privacy degli utenti. Eventualmente i cookie vengono impostati, se così previsto dai social network, solo quando l'utente fa effettivo e volontario uso del plugin. Si tenga presente che se l'utente naviga essendo loggato nel social network allora ha già acconsentito all'uso dei cookie veicolati tramite questo sito al momento dell'iscrizione al social network.
                    La raccolta e l'uso delle informazioni ottenute a mezzo del plugin sono regolati dalle rispettive informative privacy dei social network, alle quali si prega di fare riferimento.
                </p>

                <p className="subtitle" style={{ textAlign: "justify" }}>
                    <a href="" target="_blank" rel="noopener noreferrer">- Facebook</a> <br />
                    <a href="" target="_blank" rel="noopener noreferrer">- Twitter</a> <br />
                    <a href="" target="_blank" rel="noopener noreferrer">- LinkedIn</a> <br />
                    <a href="" target="_blank" rel="noopener noreferrer">- Google+</a>
                </p>

                <p className="subtitle" style={{ textAlign: "justify", marginBottom: "60px" }}>
                    La presente privacy policy può subire modifiche nel tempo – anche connesse all'eventuale entrata in vigore di nuove normative di settore, all'aggiornamento o erogazione di nuovi servizi ovvero ad intervenute innovazioni tecnologiche – per cui l'utente/visitatore è invitato a consultare periodicamente questa pagina www.supermercativivocalabria.com.
                </p>
            </div >
        </>

    );
}

export default Privacy
