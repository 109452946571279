const config = {
    baseUrl: process.env.REACT_APP_BASE_URL,
    maxSize: 5,
    DEFAULT_STORE_ID : "7231",
    DEFAULT_STORE_DESCRIPTION : "VIVOACASATUA",
    DEFAULT_STATE: "",
    DEFAULT_STATE_ID: "",
    SHOW_STATE_DROPDOWN : false,
    SHOW_CART_LISTS: false,
    SHOW_CART_UPDATE: false,

    ENABLED_SLIST: false,
    ENABLED_PAYMENTS: true
}

export default config;