import React from "react"
import AddressDropdown from "./AddressDropdown/AddressDropdown"
import { Link } from "react-router-dom"
import { addressesService } from "../../ProfileContainer/ProfileAddresses/Service"

export default class AddressSelect extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            addresses: [],
            isLoaded: false
        }
    }

    componentDidMount() {
        addressesService.getAddresses()
            .then(response => {
                console.log(response);
                this.setState({
                    addresses: response.data,
                    isLoaded: true
                })
            }).catch((error) => {
                console.log(error);
            })
    }

    render() {
        const { select, label } = this.props;
        const { addresses, isLoaded } = this.state;
        if (isLoaded) {
            if (addresses.length > 0) {
                return (<AddressDropdown items={addresses} select={select} label={label} />);
            } else {
                return (
                    <Link to="/profile/addresses">
                        <button className="dropdown-button dropdown-button-link outline mb-3">
                            <span>Aggiungi indirizzo di spedizione</span>
                        </button>
                    </Link>
                );
            }
        } else {
            return null;
        }
    }
}

