// Redux
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk"
import cart from "../../redux/store/reducers/cart"
import user from "../../redux/store/reducers/user"
import slists from "../../redux/store/reducers/slists"

const rootReducer = combineReducers({
    cart: cart,
    user: user,
    slists: slists
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

export default store; 