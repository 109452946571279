import React from "react"

function Accepts(props) {
    return (
        <>
            <div>
                <div className="custom-control form-control-lg custom-checkbox">
                    <input className="custom-control-input" type="checkbox" value="" id="accept1" name="accept1"
                        disabled={props.disabled}
                        defaultChecked={props.user && props.user.accept1}
                        ref={props.register({
                            required: "Campo obbligatorio"
                        })}
                    />
                    <label className="custom-control-label" htmlFor="accept1">
                        Dichiaro di aver letto e di accettare integralmente i termini e le condizioni di servizio indicati nella 
                        informativa per la registrazione al servizio SmartBip. In mancanza del mio consenso i servizi non potranno 
                        essere erogati.*
                    </label>
                    <div className="form-control-invalid">
                        {props.errors.accept1 && props.errors.accept1.message}
                    </div>
                </div>
            </div>

            <div className="mt-4">
                <div className="custom-control form-control-lg custom-checkbox">
                    <input className="custom-control-input" type="checkbox" value="" id="accept2" name="accept2"
                        disabled={props.disabled}
                        defaultChecked={props.user && props.user.accept2}
                        ref={props.register({
                            required: "Campo obbligatorio"
                        })}
                    />
                    <label className="custom-control-label" htmlFor="accept2">
                        Dichiaro di aver letto la Privacy Policy indicata nella informativa per la registrazione al 
                        servizio SmartBip e di esprimere il mio consenso al trattamento dei miei dati per le finalità 
                        in essa indicate. In mancanza del mio consenso, i servizi non potranno essere erogati.*
                    </label>
                    <div className="form-control-invalid">
                        {props.errors.accept2 && props.errors.accept2.message}
                    </div>
                </div>
            </div>

            <div className="mt-5">
                <div className="custom-control form-control-lg custom-checkbox">
                    <input className="custom-control-input" type="checkbox" value="" id="accept3" name="accept3"
                        defaultChecked={props.user && props.user.accept3}
                        ref={props.register}
                    />
                    <label className="custom-control-label" htmlFor="accept3">
                        Letta l'informativa, esprimo inoltre il mio consenso a che i dati da me forniti siano utilizzati per l'invio
                         di informazioni ed offerte commerciali anche di terzi. Il consenso è facoltativo.
                        </label>
                    <div className="form-control-invalid">
                        {props.errors.accept3 && props.errors.accept3.message}
                    </div>
                </div>
            </div>

            <div className="mt-4">
                <div className="custom-control form-control-lg custom-checkbox">
                    <input className="custom-control-input" type="checkbox" value="" id="accept4" name="accept4"
                        defaultChecked={props.user && props.user.accept4}
                        ref={props.register}
                    />
                    <label className="custom-control-label" htmlFor="accept4">
                        Letta l'informativa, esprimo inoltre il mio consenso per la comunicazione a terzi dei miei dati per fini 
                        promozionali. Il consenso è facoltativo.
                        </label>
                    <div className="form-control-invalid">
                        {props.errors.accept3 && props.errors.accept3.message}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Accepts;



