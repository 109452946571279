import React from "react"
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Placeholder from "../../../images/product-placeholder.png"
import CounterInput from "../../UI/Counter/CounterInput"
import translation from "../../../i18n"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import * as actionCreators from "../../../redux/store/actions/index"
import config from "../../../config/config"

class HomeProduct extends React.Component {
    constructor(props) {
        super(props);
        const item = this.props.item;
        let initialAmount = null;
        if (item.um === "PZ") {
            initialAmount = item.amount;
        } else {
            initialAmount = item.umStep;
        }
        this.state = {
            amount: initialAmount,
            umStep: item.umStep
        }
    }

    incrementAmount = () => {
        let newAmount = this.state.amount + this.state.umStep;
        newAmount = newAmount.toFixed(2);
        newAmount = parseFloat(newAmount);
        this.setState({ amount: newAmount })
    }

    decrementAmount = () => {
        let newAmount = this.state.amount - this.state.umStep;
        newAmount = newAmount.toFixed(2);
        newAmount = parseFloat(newAmount);
        if (this.state.amount > this.state.umStep) {
            this.setState({ amount: newAmount })
        }
    }

    renderPrices = (item) => {
        let result;
        if (item.price !== item.netPrice) {
            result =
                <nobr>
                    <span className="mr-1 text-strike">
                        {`${item.price.toFixed(2)} \u20AC`}
                    </span>
                    <span className="primary-color">
                        {`${item.netPrice.toFixed(2)} \u20AC`}
                    </span>
                </nobr>
        } else {
            result = <span>{`${item.netPrice.toFixed(2)} \u20AC`}</span>
        }
        return result
    }

    renderPromo = (item) => {
        if (item.promotions) {
            if (item.promotions[0] !== null && item.promotions[0] !== undefined) {
                const label = item.promotions[0].valueType === "UNKNOWN" ? "promo.unknown" : "promo";
                return (
                    <p className="primary-text text-center">
                        <FontAwesomeIcon className="mr-1" icon="thumbs-up" size="1x" />
                        {translation.t(label)}
                    </p>
                );
            } else {
                return null;
            }
        }
    }

    render() {
        const { item, user, currentSlist, cart, store, setProductToAdd } = this.props;
        const link = `/product/${item.barcode}`;
        return (
            <div className="home-item">
                <div className="home-item-content d-flex flex-column">
                    <div className="home-item-header">
                        <Link className="link" onClick={this.props.onClick}
                            to={{ pathname: link, state: { product: item } }}>
                            <div className="home-item-header-promo">
                                {this.renderPromo(item)}
                            </div>
                            <div className="home-item-image">
                                <img src={item.imageUrl ? item.imageUrl : Placeholder} alt=""
                                    onError={(e) => { e.target.src = Placeholder }}
                                />
                            </div>
                        </Link>
                    </div>
                    <div className="container-fluid home-item-actions">
                        <Row>
                            <Col sm={12} className="items pt-3 pl-2 px-0 d-flex align-items-center justify-content-center">
                                <CounterInput
                                    value={this.state.amount}
                                    increment={this.incrementAmount}
                                    decrement={this.decrementAmount}
                                />
                            </Col>

                            {config.ENABLED_SLIST &&
                                <div className="col-6 items home-button-items order-sm-3 px-0">
                                    {user &&
                                        <div className="w-100 h-100">
                                            <button className="outline w-100 h-100"
                                                onClick={() => currentSlist && this.props.itemCreate(currentSlist.id, item.barcode, 1)}>
                                                <span>
                                                    <FontAwesomeIcon icon="clipboard-list" size="1x" />
                                                </span>
                                            </button>
                                        </div>
                                    }
                                    {!user &&
                                        <div className="w-100 h-100">
                                            <button className="outline w-100 h-100"
                                                onClick={() => {
                                                    // setProductToAdd(item, this.state.amount);
                                                    document.getElementById('user').click();
                                                }}>
                                                <span>
                                                    <FontAwesomeIcon icon="clipboard-list" size="1x" />
                                                </span>
                                            </button>
                                        </div>
                                    }
                                </div>
                            }

                            <div className={`${config.ENABLED_SLIST ? "col-6" : "col-12"} items home-button-items order-sm-4 px-0`}>
                                {user && cart &&
                                    <div className="w-100 h-100">
                                        <button className="outline w-100 h-100"
                                            onClick={() => cart && store(cart.id, item.barcode, this.state.amount, item.um)}>
                                            <span>
                                                <FontAwesomeIcon icon="shopping-cart" size="1x" />
                                            </span>
                                        </button>
                                    </div>
                                }
                                {user && !cart &&
                                    <div className="w-100 h-100">
                                        <button className="outline w-100 h-100"
                                            onClick={() => {
                                                setProductToAdd(item, this.state.amount);
                                                document.getElementById('shopping-cart').click();
                                            }}>
                                            <span>
                                                <FontAwesomeIcon icon="shopping-cart" size="1x" />
                                            </span>
                                        </button>
                                    </div>
                                }
                                {!user &&
                                    <div className="w-100 h-100">
                                        <button className="outline w-100 h-100"
                                            onClick={() => {
                                                setProductToAdd(item, this.state.amount);
                                                document.getElementById('user').click();
                                            }}>
                                            <span>
                                                <FontAwesomeIcon icon="shopping-cart" size="1x" />
                                            </span>
                                        </button>
                                    </div>
                                }
                            </div>

                        </Row>
                    </div>
                </div>
                <div className="home-item-footer container-fluid">
                    <Row className="mt-3 truncate" style={{ height: "48px" }}>
                        <Col sm={12} className="pl-2 pr-1">
                            <Link className="link" onClick={this.props.onClick}
                                to={{ pathname: link, state: { product: item } }}>
                                <span>{item.description}</span>
                            </Link>
                        </Col>
                    </Row>
                    <Row className="mt-1 d-flex">
                        <Col sm={6} className="px-2 info d-flex align-items-center">
                            {`${item.netPrice.toFixed(2)} \u20AC / ${item.um} `}
                        </Col>
                        <Col sm={6} className="px-2 price d-flex flex-nowrap">
                            {this.renderPrices(item)}
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
        cart: state.cart.cart,
        currentSlist: state.slists.currentSlist,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        setProductToAdd: (item, amount) => dispatch(actionCreators.setProductToAdd(item, amount)),
        store: (cartId, barcode, amount, um) => dispatch(actionCreators.store(cartId, barcode, amount, um)),
        itemCreate: (listId, barcode, amount) => dispatch(actionCreators.itemCreate(listId, barcode, amount)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeProduct);