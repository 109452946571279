import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import './App.scss';
import MainTemplate from './components/MainComponent/Template/MainTemplate';
import Home from './features/Home/Home';
import Cart from "./features/Cart/Cart"
import Signup from "./features/Signup/Signup"
import CartCreate from "./features/CartCreate/CartCreate"
import CartUpdate from './features/CartUpdate/CartUpdate';
import Stores from "./features/Stores/Stores"
import Product from "./features/Product/Product"
import Checkout from './features/Checkout/Checkout';
import Orders from './features/Orders/Orders';
import Favorites from './features/Favorites/Favorites';
import SearchCategory from './features/SearchCategory/SearchCategory';
import Smartlist from './features/Smartlist/Smartlist';
import NewSearch from './features/NewSearch/NewSearch';
import PasswordReset from './features/PasswordReset/PasswordReset';
import ProfileContainer from './features/ProfileContainer/ProfileContainer';
import Payments from './features/Payments/Payments';
import StripeForm from './features/Payments/Stripe/StripeForm';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import BannerPopup from "./features/Home/HomeBanner/BannerModal";
import { authenticationService } from "./services/basis/AuthenticationService"
import config from "./config/config"

// custom router
import { Router } from 'react-router'
import createBrowserHistory from 'history/createBrowserHistory'

// redux
import * as actionCreators from "./redux/store/actions/index"
import { connect } from "react-redux"

// stripe
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// font awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import {
    faCalendarAlt,
    faSearch,
    faUser,
    faHeart,
    faShoppingCart,
    faBars,
    faUnlock,
    faStoreAlt,
    faPlus,
    faClipboardList,
    faCopy,
    faCreditCard,
    faMinus,
    faTimesCircle,
    faThumbsUp,
    faClipboardCheck,
    faLeaf,
    faSeedling,
    faPaw,
    faAngleLeft,
    faTrashAlt,
    faPlusCircle,
    faEdit,
    faChevronRight,
    faChevronDown
} from '@fortawesome/free-solid-svg-icons'
import Privacy from './pages/Privacy';
import TermsAndConditions from "./pages/TermsAndConditions";
import Faq from "./pages/Faq";
import CompanyData from "./pages/CompanyData";
import Contact from "./pages/Contact";

library.add(fab, faCalendarAlt, faSearch, faUser, faHeart, faShoppingCart, faBars, faUnlock, faStoreAlt, faClipboardList, faCopy, faChevronRight, faChevronDown,
    faPlus, faMinus, faTimesCircle, faThumbsUp, faClipboardCheck, faLeaf, faSeedling, faPaw, faAngleLeft, faTrashAlt, faPlusCircle, faEdit, faCreditCard, fab.faFacebookSquare)

const stripePromise = loadStripe(config.STRIPE_PKEY);

// custom history
export const history = createBrowserHistory({ basename: "/app" });

class App extends Component {
    componentDidMount() {
        if (this.props.user) {
            authenticationService.isLogged()
                .then(response => {
                    this.props.current();
                    if (config.ENABLED_SLIST) {
                        this.props.getSlists();
                        this.props.getFavorites();
                    }
                }).catch((error) => {
                    this.props.logout();
                })
        }
    }

    render() {
        return (
            <Elements stripe={stripePromise}>
                <Router history={history}>
                    <ScrollToTop>
                        <MainTemplate>
                            <Switch>
                                <Route exact path='/' component={Home} />
                                <Route exact path='/cart' component={Cart} />
                                <Route exact path='/cart/create' component={CartCreate} />
                                <Route exact path='/cart/update' component={CartUpdate} />
                                <Route exact path='/signup' component={Signup} />
                                <Route exact path='/profile' component={ProfileContainer} />
                                <Route exact path='/profile/addresses' component={ProfileContainer} />
                                <Route exact path='/profile/billing' component={ProfileContainer} />
                                <Route exact path='/reset' component={PasswordReset} />
                                <Route exact path='/stores' component={Stores} />
                                <Route exact path='/search' component={NewSearch} />
                                <Route exact path='/orders' component={Orders} />
                                <Route exact path='/smartlist' component={Smartlist} />
                                <Route exact path='/favorites' component={Favorites} />
                                <Route exact path='/payments' component={Payments} />
                                <Route exact path='/payments/stripe' component={StripeForm} />
                                <Route exact path='/product/:barcode' component={Product} />
                                <Route exact path='/category' component={SearchCategory} />
                                <Route exact path='/checkout' component={Checkout} />
                                <Route exact path='/checkout/data' component={Checkout} />
                                <Route exact path='/checkout/summary' component={Checkout} />
                                <Route exact path='/checkout/confirm' component={Checkout} />
                                <Route exact path='/privacy' component={Privacy} />
                                <Route exact path='/cgv' component={TermsAndConditions} />
                                <Route exact path='/faq' component={Faq} />
                                <Route exact path='/companyDetails' component={CompanyData} />
                                <Route exact path='/contact' component={Contact} />
                            </Switch>
                        </MainTemplate>
                    </ScrollToTop>
                    <BannerPopup />
                </Router>
            </Elements>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.user
    };
}

const mapDispatchToProps = dispatch => {
    return {
        current: () => dispatch(actionCreators.current()),
        availability: () => dispatch(actionCreators.availability()),
        logout: () => dispatch(actionCreators.logout()),
        getFavorites: () => dispatch(actionCreators.getFavorites()),
        getSlists: () => dispatch(actionCreators.getSlists()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
