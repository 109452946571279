import React from "react";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import PaymentsMode from "./PaymentsMode/PaymentsMode";
import { Redirect } from 'react-router'
import { Translation } from 'react-i18next';

class Summary extends React.Component {
  render() {
    const { cart, freeze, timeSlotId } = this.props;

    if (!timeSlotId) {
      return <Redirect to='/checkout' />;
    }

    if (cart) {
      return (
        <Translation>
          {t =>
            <div>
              <div style={{ marginBottom: "10px" }} className="signup-form-title">
                {t("checkout.summary.title")}
              </div>
              <div className="checkout-summary">
                <div className="container-fluid">
                  <Row className="mb-3">
                    <Col className="px-0">
                      {t("subTotal")}
                    </Col>
                    <Col className="px-0 text-right checkout-summary-total-text">
                      {cart && `\u20AC ${cart.netTotal.toFixed(2)}`}
                    </Col>
                  </Row>
                  {cart && cart.fees && cart.fees.map(fee => (
                    <Row className="mb-3" key={fee.id}>
                      <div className="col-8 px-0">
                        {fee.description}
                      </div>
                      <div className="col-4 px-0 text-right checkout-summary-total-text">
                        {cart && `\u20AC ${fee.value.toFixed(2)}`}
                      </div>
                    </Row>
                  ))}
                  <Row className="pt-3 mb-3 checkout-summary-total">
                    <Col className="px-0 cart-menu-message">
                      {t("total")}
                    </Col>
                    <Col className="px-0 text-right cart-menu-message">
                      {cart && cart.totalToPay ? `\u20AC ${cart.totalToPay.toFixed(2)}` : `\u20AC ${cart.netTotal.toFixed(2)}`}
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="checkout-subtitle">
                *Nel caso di pagamento con carta di credito, debito o prepagata, verrà processata una pre-autorizzazione pari all'importo della spesa + il 20%, che verrà eliminata nel momento della preparazione della consegna.
            </div>

              <hr />

              <PaymentsMode cart={cart} freeze={freeze} />

            </div>
          }
        </Translation>
      )
    } else return null
  }
}

export default Summary


