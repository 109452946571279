import React, { useState, useEffect } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { stripeService } from "./Service"

import CardSection from './CardSection';
import { Container } from 'react-bootstrap';
import { connect } from "react-redux"

import "./stripe-form.styles.scss";
import { withRouter } from 'react-router-dom';

function StripeForm(props) {
    const stripe = useStripe();
    const elements = useElements();
    const [clientSecret, setClientSecret] = useState("");

    useEffect(() => {
        stripeService.stripeClienSecret()
            .then(response => {
                setClientSecret(response);
            }).catch(error => {
                console.log(error);
            })
    }, [])

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements || !clientSecret) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const result = await stripe.confirmCardSetup(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                    // name: "",
                    // address: "",
                    // email: "",
                    // phone: ""
                },
            }
        });

        if (result.error) {
            // Display result.error.message in your UI.
        } else {
            console.log(result);
            stripeService.stripeBoarding()
                .then(response => {
                    if (props.checkoutInfo) props.history.push("/checkout/data")
                    else props.history.push("/payments")
                }).catch(error => {
                    console.log(error);
                })
            // The setup has succeeded. Display a success message and send
            // result.setupIntent.payment_method to your server to save the
            // card to a Customer
        }
    };

    return (
        <Container className="signup-wrapper">
            <div className="signup-form">
                <div className="text-center pb-4">
                    <h1 className="title pb-2">La tua carta</h1>
                    <h1 className="subtitle pb-2">
                        Inserisci i dati della tua carta per pagare le tue spese in modo sicuro e veloce.<br />
                        I tuoi dati verranno utilizzati unicamente per i pagamenti su questo sito.
                    </h1>
                </div>
                <form style={{ paddingBottom: "200px" }} onSubmit={handleSubmit}>
                    <CardSection />
                    <div className="text-center pt-5">
                        <button className="stripe-button outline" disabled={!stripe}>
                            <span>Salva</span>
                        </button>
                    </div>
                </form>
            </div>
        </Container>
    );
}

const mapStateToProps = state => {
    return {
        checkoutInfo: state.cart.checkoutInfo
    };
}

export default withRouter(connect(mapStateToProps)(StripeForm))