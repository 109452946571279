import React from "react";

import "../../CartItem/cart-item.styles.scss";

class FreezeCheckItem extends React.Component {
    renderDetails = (isUpdate) => {
        if (isUpdate) {
            return (
                <p className="check-item-updated">
                    Variazione prezzo
                </p>
            );
        } else {
            return (
                <p className="check-item-removed">
                    Momentaneamente non disponibile
                </p>
            );
        }
    }

    render() {
        const { item, isUpdate } = this.props;
        return (
            <div className="article">
                <div className="row no-gutters">
                    {/* Only for desktop device */}
                    {/* <div className="article__image d-none d-sm-block">
                        <img src={item.imageUrl ? item.imageUrl : Placeholder} alt=""
                            onError={(e) => { e.target.src = Placeholder }}
                        />
                    </div> */}
                    <div className="col pl-0 px-sm-0 pr-sm-4">
                        <div className="container-fluid article__centerer">
                            <div className="row" >
                                {/* Only for mobile device */}
                                {/* <div className="col-2 d-block d-sm-none article__image">
                                    <img src={item.imageUrl ? item.imageUrl : Placeholder} alt=""
                                        onError={(e) => { e.target.src = Placeholder }}
                                    />
                                </div> */}
                                <div className="col-12 col-sm-12 pr-0 my-auto">
                                    <div className="article__info">
                                        <p className="article__name">{item.description}</p>
                                        <span className="article__promo">{this.renderDetails(isUpdate)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <hr style={{ margin: "unset" }} /> */}
                <hr className="hr-items" />
            </div>
        );
    }
}

export default FreezeCheckItem;