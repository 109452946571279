import React from "react"
import Placeholder from "../../../images/product-placeholder.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import * as actionCreators from "../../../redux/store/actions/index"
import config from "../../../config/config"
import { ReactComponent as SlistIcon } from "../../../images/slist.svg"
import { history } from "../../../App";
import { ReactComponent as PiecesPerSetIcon } from '../../../images/piecesPerSet.svg';
import { ReactComponent as MaxIcon } from '../../../images/tags/ico_so_max.svg';
import { ReactComponent as PesoVarIcon } from '../../../images/tags/ico_so-pesovar2.svg';
import { tags } from "../../../utils/renderTags"

import "./card-product.styles.scss";

class CardProduct extends React.Component {
    renderPrices = (item) => {
        if (item.price !== item.netPrice) {
            return (
                <nobr>
                    <span className="text-strike strike">
                        {`${item.price.toFixed(2)} \u20AC`}
                    </span>
                </nobr>
            );
        }
    }

    renderPromo = (item) => {
        if (item.promotions) {
            if (item.promotions[0] !== null && item.promotions[0] !== undefined) {
                // const label = item.promotions[0].valueType === "UNKNOWN" ? "promo.unknown" : "promo";
                return (
                    <span>
                        {/* {translation.t(label)} */}
                        Offerta!
                    </span>
                );
            } else {
                return null;
            }
        }
    }

    isAlreadyBooked = (item) => {
        var isAlreadyBooked = false;
        var itemToAdd = null;
        if (this.props.cart.items) {
            const items = this.props.cart.items;
            for (var i = 0; i < items.length; i++) {
                if (items[i].ref === item.ref) {
                    isAlreadyBooked = true;
                    itemToAdd = items[i];
                    break;
                }
            }
        } else {
            if (item.vatConfigurationId === "KG") {
                history.push({
                    pathname: `/product/${item.barcode}`,
                    state: { product: item }
                })
            } else {
                this.props.store(this.props.cart.id, item.barcode, item.umStep, item.um);
            }
            return;
        }

        if (isAlreadyBooked) {
            const addItem = { ...itemToAdd, available: true }
            history.push({
                pathname: `/product/${item.barcode}`,
                state: { product: addItem, isDetail: true }
            })
        } else {
            if (item.vatConfigurationId === "KG") {
                history.push({
                    pathname: `/product/${item.barcode}`,
                    state: { product: item }
                })
            } else {
                this.props.store(this.props.cart.id, item.barcode, item.umStep, item.um);
            }
        }
    }

    render() {
        const { item, user, currentSlist, cart, setProductToAdd, from, category, mainCategory, categoryTitle, subCategory, activeKey } = this.props;
        const link = `/product/${item.barcode}`;
        return (
            <div className="card-product" style={{ ...this.props.style }}>
                <div className={"card-product-container"}>
                    <div className={"card-product-image"}>
                        <Link className="link" onClick={this.props.onClick}
                            to={{ pathname: link, state: { product: item, from, category, mainCategory, categoryTitle, subCategory, activeKey } }}>
                            <img src={item.imageUrl ? item.imageUrl : Placeholder} alt=""
                                onError={(e) => { e.target.src = Placeholder }}
                            />
                        </Link>
                    </div>
                    <div className={"card-product-promo"}>
                        {this.renderPromo(item)}
                    </div>
                </div>
                <div className="card-product-details">
                    <Link className="link" onClick={this.props.onClick}
                        to={{ pathname: link, state: { product: item, from } }}>
                        <span>{item.description}</span>
                    </Link>
                </div>
                <div className="card-product-um">
                    {item.attrib1 && item.attrib3 &&
                        `${item.attrib3} \u20AC / ${item.attrib1}`
                    }
                    <span className="icons">
                        {item.tags &&
                            <span>{tags.renderTags(item.tags)}</span>
                        }
                        {item.piecesPerSet &&
                            <>
                                <span className="pl-1"><PiecesPerSetIcon title={"Unità di vendita"} /></span>
                                <span className="pl-sm-2 d-none d-sm-inline">{item.piecesPerSet} PZ</span>
                            </>
                        }
                        {item.vatConfigurationId === "KG" &&
                            <span className="pl-1"><PesoVarIcon title={"Prodotto a peso variabile"} /></span>
                        }
                        {item.maxAmount &&
                            <>
                                <span className="pl-1"><MaxIcon title={"Massimo pezzi acquistabili"} /></span>
                                <span className="pl-sm-2 d-none d-sm-inline">{item.maxAmount} PZ</span>
                            </>
                        }
                    </span>
                </div>
                <div className="card-product-prices">
                    {this.renderPrices(item)}
                    <span></span>
                    <nobr><span className="netTotal">{`${item.netPrice.toFixed(2)} \u20AC / ${item.um}`}</span></nobr>
                </div>
                {item.available &&
                    <div className="card-product-buttons row">
                        {config.ENABLED_SLIST &&
                            <div className="col-4 col-sm-2">
                                {user &&
                                    <button className="slist outline"
                                        onClick={() => currentSlist && this.props.itemCreate(currentSlist.id, item.barcode, 1)}>
                                        <SlistIcon />
                                    </button>
                                }
                                {!user &&
                                    <button className="slist outline"
                                        onClick={() => { document.getElementById('user').click(); }}>
                                        <SlistIcon />
                                    </button>
                                }
                            </div>
                        }
                        <div className={`${config.ENABLED_SLIST ? "col-4 col-sm-10" : "col-12"}`}>
                            {user && cart &&
                                <button style={buttonStyle} className="cart outline"
                                    // onClick={() => cart && store(cart.id, item.barcode, 1, item.um)}>
                                    onClick={() => cart && this.isAlreadyBooked(item)}>
                                    <FontAwesomeIcon icon="shopping-cart" style={{ width: "18px", height: "auto" }} />
                                    <span className="d-none d-sm-inline">ACQUISTA</span>
                                </button>
                            }
                            {user && !cart &&
                                <button style={buttonStyle} className="cart outline"
                                    onClick={() => {
                                        setProductToAdd(item, 1);
                                        document.getElementById('shopping-cart').click();
                                    }}>
                                    <FontAwesomeIcon icon="shopping-cart" style={{ width: "18px", height: "auto" }} />
                                    <span className="d-none d-sm-inline">ACQUISTA</span>
                                </button>
                            }
                            {!user &&
                                <button style={buttonStyle} className="cart outline"
                                    onClick={() => {
                                        setProductToAdd(item, 1);
                                        document.getElementById('user').click();
                                    }}>
                                    <FontAwesomeIcon icon="shopping-cart" style={{ width: "18px", height: "auto" }} />
                                    <span className="d-none d-sm-inline">ACQUISTA</span>
                                </button>
                            }
                        </div>
                    </div>
                }
                {!item.available &&
                    <div className="card-product-unavailable">
                        <div className="unavailable-content">
                            <span>Momentaneamente <br /> non disponibile</span>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

const buttonStyle = {
    width: config.ENABLED_SLIST ? "" : "100%",
    marginLeft: config.ENABLED_SLIST ? "" : "unset",
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
        cart: state.cart.cart,
        currentSlist: state.slists.currentSlist,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        setProductToAdd: (item, amount) => dispatch(actionCreators.setProductToAdd(item, amount)),
        store: (cartId, barcode, amount, um) => dispatch(actionCreators.store(cartId, barcode, amount, um)),
        itemCreate: (listId, barcode, amount) => dispatch(actionCreators.itemCreate(listId, barcode, amount)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CardProduct);