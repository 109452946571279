import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const FavoriteButton = (props) => {
    return (
        <button className="button-clean outline"
            onClick={props.setFavorite}>
            <span style={ props.isFavorite ? { color: "red" } : null}>
                <FontAwesomeIcon icon="heart" size="2x" />
            </span>
        </button>
    );
}

export default FavoriteButton;