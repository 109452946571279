import React from "react";
import Row from "react-bootstrap/Row"
import PaymentsLogo from "../../../../images/payment_circuits.png"
import payments from "./Service"
import { paymentsService } from "../../../Payments/Service"
import i18n from "../../../../i18n"
import { Translation } from 'react-i18next';
import { Link } from "react-router-dom";

class PaymentsMode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentsMode: [],
            isStripeBoarded: false
        };
    }

    freezeCart = (paymentMode) => {
        const { freeze, cart } = this.props;
        freeze(cart.id, paymentMode);
    }

    componentDidMount() {
        const deliveryMode = this.props.cart.deliveryMode;
        payments(deliveryMode)
            .then(response => {
                this.setState({
                    paymentsMode: response.data,
                })
            }).catch((error) => {
                console.log(error);
            })

        paymentsService.paymenthMethods()
            .then(response => {
                console.log(response);
                if (response.data.length > 0) {
                    response.data.forEach(method => {
                        if (method.paymentMethodType === "XPAY") {
                            this.setState({ isStripeBoarded: true })
                        }
                    });
                }
            }).catch((error) => {
                console.log(error);
            })
    }

    renderButtons = (paymentMode) => {
        switch (paymentMode) {
            case "CASH":
                return (
                    <>
                        {/* Only on large device */}
                        <button className="outline d-none d-lg-block" onClick={() => this.freezeCart(paymentMode)}>
                            <div className="to-hide">
                                <span className="primary-text" style={{ fontSize: "12px" }}>{i18n.t("paymentMode.cash.title")}</span>
                                {/* <br></br>
                                <span>{i18n.t("paymentMode.cash.subTitle")}</span> */}
                            </div>
                            <div className="hover-text">{i18n.t("paymentMode.CASH")}</div>
                        </button>
                        {/* Only on small device */}
                        <button className="outline d-lg-none mobile" onClick={() => this.freezeCart(paymentMode)}>
                            <div>{i18n.t("paymentMode.CASH")}</div>
                        </button>
                    </>
                );
            case "CREDIT_CARD":
                return (
                    <>
                        {/* Only on large device */}
                        <button className="outline d-none d-lg-block" onClick={() => this.freezeCart(paymentMode)}>
                            <img className="to-hide" src={PaymentsLogo} alt="" />
                            <div className="hover-text">{i18n.t("paymentMode.CREDIT_CARD")}</div>
                        </button>
                        {/* Only on small device */}
                        <button className="outline d-lg-none mobile" onClick={() => this.freezeCart(paymentMode)}>
                            <div>{i18n.t("paymentMode.CREDIT_CARD")}</div>
                        </button>
                    </>
                );
            case "XPAY":
                if (this.state.isStripeBoarded) {
                    return (
                        <>
                            {/* Only on large device */}
                            <button className="outline d-none d-lg-block" onClick={() => this.freezeCart(paymentMode)}>
                                <div className="to-hide">
                                    <span className="primary-text" style={{ fontSize: "12px" }}>
                                        {i18n.t("paymentMode.online.title")}</span>
                                    {/* <br></br>
                                    <span>{i18n.t("paymentMode.stripe.subTitle")}</span> */}
                                </div>
                                <div className="hover-text">{i18n.t("paymentMode.STRIPE")}</div>
                            </button>
                            {/* Only on small device */}
                            <button className="outline d-lg-none mobile" onClick={() => this.freezeCart(paymentMode)}>
                                <div>{i18n.t("paymentMode.STRIPE")}</div>
                            </button>
                        </>
                    );
                } else {
                    return (
                        <Link style={{ width: "100%" }} to="/payments">
                            <div className="addMethod">
                                <span className="primary-text" style={{ fontSize: "12px" }}>
                                    Aggiungi metodo di pagamento online
                                </span>
                            </div>
                        </Link>
                    );
                }
            default: return null;
        }
    }

    render() {
        const { paymentsMode } = this.state;
        return (
            <Translation>
                {t =>
                    <div className="w-100">
                        <div style={{marginBottom: "20px"}} className="signup-form-title" >
                            {t("checkout.paymentsMode.title")}
                        </div>
                        <div className="container-fluid">
                            {paymentsMode && paymentsMode.reverse().map(paymentMode => (
                                <Row key={paymentMode}>
                                    {this.renderButtons(paymentMode)}
                                </Row>
                            ))}
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}

export default PaymentsMode;


