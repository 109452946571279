import React from "react";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";
import { ReactComponent as VivoLogo } from "../../../images/vivo-logo.svg";
// import Cgv from "../../../pages/TermsAndConditions"
// import InstagramIcon from "../../../images/instagram-square-brands.svg";
// import FacebookIcon from "../../../images/facebook-square-brands.svg";

import "./footer.styles.scss";

export default class Footer extends React.Component {
    render() {
        return (
            <div className="footer">
                <CookieConsent
                    location="bottom"
                    buttonText="OK"
                    style={{ background: "#2B373B" }}
                    buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                    expires={150}>
                    Utilizziamo i cookie per essere sicuri che tu possa avere la migliore esperienza sul nostro sito.
                    Continuando a navigare nel sito si accetta la nostra politica cookie.
                </CookieConsent>
                <div className="footer-content">
                    <div className="row container-fluid">
                        <div className="col-lg-3 col-md-12 text-center text-lg-left mb-4 mb-lg-0 logo">
                            <VivoLogo />
                        </div>
                        <div className="col-lg-2 offset-lg-1 col-md-4 col-12 text-center text-md-left">
                            <div className="footer-link">
                                <Link style={{ color: "#ffffff" }} to="/privacy">
                                    Privacy Policy
                                </Link>
                            </div>
                            <div className="footer-link">
                                <Link style={{ color: "#ffffff" }} to="/faq">
                                    FAQ
                                </Link>
                            </div>
                            {/* <div className="footer-link">
                                <a href=" https://www.frescomarket.it/spesaonline_faq.cfm?idtipo=1"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: "#ffffff" }}
                                >
                                    Domande Frequenti
                                </a>
                            </div>
                            <div className="footer-link">
                                <a href="https://www.frescomarket.it/pag.cfm?p=call_center_spesa_online&sez=spesaonline"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: "#ffffff" }}
                                >
                                    Contatti
                                </a>
                            </div> */}
                        </div>
                        <div className="col-lg-2 col-md-4 col-12 text-center text-md-left mb-3 mb-lg-0  ">
                            <div className="footer-link">
                                <Link style={{ color: "#ffffff" }} to="/companyDetails">
                                    Dati Societari
                                </Link>
                            </div>
                            <div className="footer-link">
                                <Link style={{ color: "#ffffff" }} to="/cgv">
                                    Condizioni di vendita
                                </Link>
                            </div>

                            {/* <div className="footer-link">
                                <a href=""
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: "#ffffff" }}
                                >
                                    Condizioni di vendita
                                </a>
                            </div>
                            <div className="footer-link">
                                <a href="https://www.frescomarket.it/privacy.cfm?sez=spesaonline"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: "#ffffff" }}
                                >Privacy e Cookies
                                </a>
                            </div> */}
                        </div>
                        <div className="col-lg-2 col-md-4 col-12 text-center text-md-left mb-3 mb-lg-0  ">
                            <div className="footer-link">
                                <Link style={{ color: "#ffffff" }} to="/contact">
                                    Contatti
                                </Link>
                            </div>
                        </div>
                        {/* <div className="col-lg-3 offset-lg-1 col-md-4 col-12 text-center text-md-left">
                            <div className="footer-text">
                                Vuoi rimanere aggiornato sulle nostre attività?
                            </div>
                            <div className="footer-text last">
                                Visita il nostro
                                <a href="https://www.frescomarket.it/index.cfm"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: "#ffffff" }}
                                >
                                    <span className="bold"> Sito istituzionale</span>
                                </a>
                            </div>
                            <div className="footer-text footer-social d-flex">
                                <span>Seguici su:</span>
                                <a href="https://www.facebook.com/frescomarket.it"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="footer-social-icon"
                                >
                                    <img src={FacebookIcon} alt="facebook" />
                                </a>
                                <a href="https://www.instagram.com/frescomarket_it/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="footer-social-icon"
                                >
                                    <img src={InstagramIcon} alt="instagram" />
                                </a>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="footer-bottom row no-gutters">
                    <div className="col-12 col-sm-8 text-center text-sm-left" >
                        {/* <span>
                            © 2020 Tutti i diritti riservati. C.F. e P.I. 11247460154
                        </span> */}
                    </div>
                    <div className="col-12 col-sm-4 text-center text-sm-right">
                        <span>
                            Powered by <span className="bold">SmartBip</span>
                        </span>
                    </div>
                    {/* <span>
                            © 2020 Tutti i diritti riservati. C.F. e P.I. 11247460154
                    </span>
                    <span>
                            Powered by <span className="bold">Retapps</span>
                    </span> */}
                </div>
            </div>
        );
    }
}