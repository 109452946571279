import React from "react"
import Container from "react-bootstrap/Container"
import SignupForm from "./SignupForm/SignupForm"

export default class Signup extends React.Component {
    
    render() {
        return (
            <Container className="signup-wrapper">
                <SignupForm />
            </Container>
        );
    }
}