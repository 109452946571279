import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Translation } from "react-i18next";

const GoBackButton = (props) => {
    return (
        <Translation>
            {t =>
                <button className="go-back-button outline"
                    onClick={props.goBack}>
                    <FontAwesomeIcon className="mr-2 pt-1" icon="angle-left" size="1x" />
                    {props.children}
                </button>
            }
        </Translation>
    );
}

export default GoBackButton;