import React from "react"
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import CustomInput from "../../../components/UI/Input/CustomInput"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Translation } from "react-i18next";
import { connect } from "react-redux"
import * as actionCreators from "../../../redux/store/actions/index"

const SearchText = (props) => (
    <Translation>
        {t =>
            <div className="navigation-view container-fluid" style={{ maxWidth: "100%" }}>
                <Row className="g-padding mt-3">
                    <Col className="login-icon" style={{ maxWidth: "3%" }} xs={1}>
                        <FontAwesomeIcon icon="search" size="1x" />
                    </Col>
                    <Col xs={9} md={6}>
                        <div className="input-group mb-3">
                            <div className="w-100">
                                <CustomInput
                                    label={"searchInput"}
                                    value={props.searchInput}
                                    onChange={props.onChange}
                                    type="text"
                                />
                            </div>
                        </div>
                    </Col>
                    <Col xs={2} className="d-none d-md-block">
                        <button className={`button-clean outline mt-3 primary-text ${props.searchInput.length < 3 ? "button-clean-disabled" : ""}`}
                            onClick={() => props.currentSlist && props.itemCreateCustom(props.currentSlist.id, 1, props.searchInput)}>
                            <FontAwesomeIcon icon="plus-circle" style={{ width: "30px", height: "22px" }} />
                        </button>
                    </Col>
                </Row>
            </div>
        }
    </Translation>
);

const mapStateToProps = state => {
    return {
        currentSlist: state.slists.currentSlist,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        itemCreateCustom: (listId, amount, description) => dispatch(actionCreators.itemCreateCustom(listId, amount, description)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchText);

