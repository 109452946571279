import React from "react";
import Popup from "reactjs-popup";
import vivoLogo from "../../../images/VIVO.svg";
import deliveryHomeLogo from "../../../images/noun-delivery-1238130.svg";
import pickupStoreLogo from "../../../images/noun-store-850694.svg";
import { storesService } from "../../../services/basis/StoresService";
import errorLogo from "../../../images/noun-error-1102749.svg";
import VivoNewLogo from "../../../images/VIVOACASATUA LOGO-1.png";

import "./BannerModal.scss";
import { withRouter } from "react-router-dom";

class BannerModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: this.props.location.pathname === "/" ? true : false,
            stores: null,
            selectedStore: null,
            submitted: false
        };
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    openModal() {
        this.setState({ open: true });
    }

    closeModal() {
        this.setState({ open: false });
    }

    componentDidMount() {
        storesService.storesList()
            .then(response => {
                console.log(response);
                this.setState({
                    stores: response.data,
                })
            }).catch((error) => {
                console.log(error);
            })
    }

    onSubmit = (e) => {
        console.log("submit")
        e.preventDefault();
        this.setState({ submitted: true })
    }

    handleChange = (e) => {
        e.preventDefault();
        if (e.target.value) {
            this.setState({
                [e.target.name]: JSON.parse(e.target.value),
                submitted: false
            })
        } else {
            this.setState({ submitted: false, selectedStore: null })
        }
    }

    render() {
        const { open, stores, selectedStore, submitted } = this.state;
        return (
            <Popup
                open={open}
                closeOnDocumentClick
                onClose={this.closeModal}
                contentStyle={popupStyle}
            >
                {close => (
                    <div className="banner-comune-modal-popup">
                        <div id="comune-close-popup" className="close" onClick={close}>
                            &times;
                        </div>
                        <div className="banner-comune-modal-popup-header">
                            <div className="banner-comune-logo">
                                <img className="banner-vivo-image" src={VivoNewLogo} alt="" />
                            </div>
                            <div className="sub-comune-banner-header-title">
                                <div>
                                    INSERISCI IL TUO COMUNE E VERIFICA
                                    SE IL SERVIZIO È ATTIVO
                                </div>
                            </div>
                        </div>
                        <div className="banner-comune-modal-popup-content">
                            <div className="comune-input-div w-100 d-flex">
                                <select className="comune-input" name="selectedStore" onChange={this.handleChange}>
                                    <option value="">SELEZIONA COMUNE</option>
                                    {stores && stores.map(item => (
                                        <option key={item.id} value={JSON.stringify(item)}>
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                                <div className="banner-comune-modal-popup-actions">
                                    <button disabled={!selectedStore} onClick={(e) => this.onSubmit(e)}
                                        className="verifica-comune outline">
                                        <span className="text-verifica">Verifica</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        {submitted &&
                            <div>
                                <hr />
                                <div>
                                    {selectedStore && (selectedStore.enabledDeliveryInStore || selectedStore.enabledDeliveryAtHome) &&
                                        <div className="mb-2">
                                            <div className="delivery-text">Servizi attivi nel comune di &nbsp;
                                        <div style={{
                                                    color: "#0097d1",
                                                    fontFamily: "Futura-Bold",
                                                    fontSize: "20px",
                                                    align: "center",
                                                    letterSpacing: "-0.28px"
                                                }}> {selectedStore.name}</div>
                                            </div>
                                        </div>}
                                    {selectedStore && (selectedStore.enabledDeliveryAtHome || selectedStore.enabledDeliveryInStore) &&
                                        <div className="delivery">
                                            {selectedStore && selectedStore.enabledDeliveryAtHome &&
                                                <div className="delivery-home" onClick={this.closeModal}>
                                                    <div className="delivery-container">
                                                        <div className="delivery-logo">
                                                            <img src={deliveryHomeLogo} alt="" />
                                                        </div>
                                                        <div className="link-delivery">CONSEGNA A DOMICILIO</div>
                                                    </div>
                                                </div>}
                                            {selectedStore && selectedStore.enabledDeliveryInStore &&
                                                <div className="delivery-home" onClick={this.closeModal}>
                                                    <div className="delivery-container">
                                                        <div className="delivery-logo">
                                                            <img src={pickupStoreLogo} alt="" />
                                                        </div>
                                                        <div className="link-delivery">RITIRO IN NEGOZIO</div>
                                                    </div>
                                                </div>}
                                        </div>
                                    }
                                </div>
                                {selectedStore && !selectedStore.enabledDeliveryInStore && !selectedStore.enabledDeliveryAtHome &&
                                    <div className="d-flex banner-spiacenti">
                                        <div>
                                            <img className="errorLogo-icon" src={errorLogo} alt="" />
                                        </div>
                                        <div className="banner-siamo-spiacenti mt-2">
                                            Siamo spiacenti,
                                </div>
                                        <div className="banner-siamo-spiacenti">
                                            nel comune di <span style={{
                                                color: "#0097d1",
                                                fontFamily: "FuturaStd-Bold",
                                                fontSize: "20px",
                                                align: "center",
                                                letterSpacing: "-0.28px"
                                            }}>{selectedStore.name}</span> non ci sono servizi attivi
                                </div>
                                    </div>}
                            </div>
                        }
                    </div>
                )}
            </Popup>
        );
    }
}

const popupStyle = {
    borderRadius: "15px",
    boxShadow: "0 0 16px 0 rgba(0, 0, 0, 0.21)"
}

export default withRouter(BannerModal);

