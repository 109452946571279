import React from "react"
import Placeholder from "../../images/product-placeholder.png"
import { Translation } from "react-i18next";

export default class StoreCard extends React.Component {
    render() {
        const { item, selectStore, selectedStore } = this.props;
        if (item) {
            return (
                <Translation>
                    {t =>
                        <div className={`${item.id === selectedStore.id ? "card-selected" : ""} card w-100 mb-3`} onClick={() => { selectStore(item) }}>
                            <div className="row no-gutters">
                                <div className="col-md-4">
                                    <img width="100%" height="100%" src={item.logoImageUrl ? item.logoImageUrl : Placeholder}
                                        alt="" onError={(e) => { e.target.src = Placeholder }}
                                    />
                                </div>
                                <div className="col-md-8">
                                    <div className="card-body">
                                        <h5 className="card-title title">{item.name}</h5>
                                        <p className="card-text card-subtitle">{item.address && item.address.address && item.address.address}</p>
                                        <p className="card-text card-text-muted">{item.phone && item.phone} {item.email && `- ${item.email}`}</p>
                                        {item.distance &&
                                            <p className="card-text-muted">
                                                {t("distance")}: {`${item.distance} Km`}
                                            </p>
                                        }
                                        {!item.distance &&
                                            <p className="card-text card-text-muted">&nbsp;</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </Translation>
            );
        } else {
            return null;
        }
    }
}