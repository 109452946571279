import React, {Component} from 'react';

class Faq extends Component {
    render() {
        return (
            <div className="container mb-5">
                <div style={{ marginTop: "40px"}}>
                <ol>
                <li className="mb-2 title" style={{textDecoration:"underline"}}>1. Come funziona vivoacasatua.com?</li>
                <p> Basta collegarsi al sito www.vivoacasatua.com, accedere all’area dedicata e seguire le indicazioni per
                    registrarsi. In pochi istanti è possibile iniziare a fare la spesa selezionando il servizio, la data e
                    l’ora che si preferisce. Occorre infine scegliere la modalità di pagamento tra quelle disponibili e
                    ritirare/ricevere la propria spesa.</p>
                <li className="mb-2 title" style={{textDecoration:"underline"}}>2. Quando posso ordinare?</li>
                <p>In qualsiasi momento.</p>
                <li className="mb-2 title" style={{textDecoration:"underline"}}>3. Quando posso ritirare la spesa per il servizio ritiro in negozio?</li>
                <p>Dal lunedì al sabato nelle fasce orarie disponibili al momento dell’ordine.</p>
                <li className="mb-2 title" style={{textDecoration:"underline"}}>
                    4. Quando posso ricevere la spesa per il servizio consegna a domicilio?</li>
                <p> Dal lunedì al venerdì (a seconda del tipo di servizio o punto vendita selezionato), dalle 08:00 alle
                    20:00 per il ritiro in negozio, dalle 8:00 alle 20:00 per il servizio consegna domicilio.</p>
                <li className="mb-2 title" style={{textDecoration:"underline"}}>5. Quali sono i vantaggi del servizio ritiro in negozio?</li>
                <p> Il servizio Vivoacasatua.com, nella versione Ritiro in Negozio, è nato per offrire numerosi vantaggi ai
                    clienti che vogliono effettuare la spesa comodamente da casa e ritirarla nel loro punto vendita
                    preferito. Il tempo necessario per effettuare i propri acquisti sarà notevolmente ridotto e le attese
                    alla cassa completamente annullate. Si potrà ritirare comodamente la spesa durante i propri tragitti
                    quotidiani verso casa, evitando l’attesa di un corriere per la consegna del proprio ordine.</p>
                <li className="mb-2 title" style={{textDecoration:"underline"}}>6. Quali sono i vantaggi del servizio consegna a domicilio?</li>
                <p>Il servizio Vivoacasatua.com, nella versione Consegna a Domicilio, è nato per offrire un’ulteriore
                    comodità ai clienti che vogliono effettuare la spesa comodamente da casa e riceverla direttamente a
                    domicilio nei comuni serviti.</p>
                <li className="mb-2 title" style={{textDecoration:"underline"}}>7. Quanto costa il servizio spesa online con ritiro in negozio?</li>
                <p>Il servizio è completamente gratuito.</p>
                <li className="mb-2 title" style={{textDecoration:"underline"}}>8. Quanto costa il servizio spesa online consegna a domicilio?</li>
                <p>Il servizio è momentaneamente gratuito, e rappresenta un piccolo contributo di Vivo per fronteggiare
                    l’emergenza da Coronavirus.</p>
                <li className="mb-2 title" style={{textDecoration:"underline"}}>9. È richiesto un minimo d’ordine per il servizio ritiro in negozio?</li>
                <p>Si, è richiesta una spesa di almeno 30 €.</p>
                <li className="mb-2 title" style={{textDecoration:"underline"}}>10. È richiesto un minimo d’ordine per il servizio consegna a domicilio?</li>
                <p>Sì, è richiesta una spesa di almeno € 50,00</p>
                <li className="mb-2 title" style={{textDecoration:"underline"}}>11. I sacchetti sono a pagamento?</li>
                <p>I sacchetti utilizzati per la preparazione delle spese sono a pagamento (€ 0,10 cad.) e vengono
                    addebitati in modo commisurato in funzione della necessità di ogni singola spesa. Il numero di sacchetti
                    addebitati è visionabile nello scontrino al momento della consegna/ritiro della spesa.</p>
                <li className="mb-2 title" style={{textDecoration:"underline"}}>12. Quale assortimento e offerte sono presenti sul sito vivoacasatua.com?</li>
                <p>Sul sito sono presenti tutti i prodotti che trovi nel tuo supermercato Vivo di fiducia, gli stessi
                    prezzi e tutte le offerte previste dal volantino.</p>
                <li className="mb-2 title" style={{textDecoration:"underline"}}>13. Posso ordinare anche i prodotti a peso variabile?</li>
                <p> Sì. Il prezzo di molti prodotti a peso variabile (ad esempio frutta, verdura, carne, formaggi, salumi,
                    prodotti di gastronomia e panetteria) è espresso a peso e opportunamente indicato sul sito. Per questi
                    prodotti, il peso effettivo della merce consegnata si determinerà solo al momento della preparazione del
                    prodotto stesso, e di conseguenza, anche il prezzo.</p>
                <li className="mb-2 title" style={{textDecoration:"underline"}}>14. Posso modificare o annullare il mio ordine anche se l’ho già confermato?</li>
                <p>   L’ordine potrà essere annullato fino a quando non verrà visualizzato lo stato “in preparazione”. Per
                    vedere il tuo stato clicca su “I TUOI ORDINI” nella tua area personale. In caso di esigenze particolari
                    puoi contattare il numero del “PUNTO VENDITA” selezionato al momento dell’ordine o scrivere a
                    servizioclienti@vivoacasatua.com. Puoi trovare il numero telefonico di ciascun supermercato nella
                    sezione NEGOZI. <br/>
                    La modifica dell’ordine può avvenire solo per il giorno e la fascia oraria di consegna fino a quando non
                    verrà visualizzato lo stato “in preparazione”, chiamando al numero telefonico del punto vendita scelto
                    oppure scrivendo all’indirizzo servizioclienti@vivoacasatua.com <br/>
                    VIVO potrà decidere di annullare l’ordine a causa di difficoltà organizzative, dandone SEMPRE
                    comunicazione al cliente tramite mail oppure contattandolo telefonicamente. VIVO potrà modificare il
                    giorno e/o l’ora di ritiro/consegna della spesa previo accordo con il cliente.</p>
                <li className="mb-2 title" style={{textDecoration:"underline"}}>15. Come e quando posso pagare?</li>
                <p>  Per il servizio “ritiro in negozio” i metodi di pagamento accettati sono: – al momento del ritiro con
                    Carta di Credito, Carta di Debito, Carta Prepagata, Contanti; – online con Carta di Credito (VISA,
                    Mastercard, Maestro, American Express), Carta di Debito o Prepagata abilitata ai pagamenti online.
                    Per il servizio “consegna a domicilio” il metodo di pagamento accettato è: – online con Carta di Credito
                    (VISA, MasterCard, Maestro, American Express), Carta di Debito o Prepagata abilitata ai pagamenti
                    online; <br/>
                     al momento della consegna in contanti o carte.</p>
                <li className="mb-2 title" style={{textDecoration:"underline"}}>16. Come funziona il pagamento online?</li>
                <p>    Il pagamento online può essere effettuato tramite Carta di Credito, Carta di Debito o Prepagata
                    abilitata. VIVO provvede ad effettuare una pre-autorizzazione del valore confermato al momento della
                    chiusura dell’ordine, maggiorata del 20% sulla base dei prodotti a peso variabile contenuti nella spesa.
                    Sul conto corrente bancario del cliente sarà indicata tale operazione ma NON COSTITUIRÀ UN REALE
                    ADDEBITO.</p>
                <p>  Il reale addebito avverrà successivamente alla consegna/ritiro della spesa da parte del cliente, sulla
                    base dell’effettivo importo dello scontrino. <span style={{fontWeight:"bold", fontSize: "20px"}}>L’importo di pre-autorizzazione verrà sbloccato con
                    tempistiche differenti a seconda del proprio circuito di credito (mediamente non meno di 21 giorni) ed
                    indipendentemente dalla volontà di VIVO.</span></p>
                <li className="mb-2 title" style={{textDecoration:"underline"}}>17. Posso richiedere la fattura? </li>
                <p> Sì, è possibile intestare la fattura alla tua azienda, facendone richiesta spuntando l'apposita
                    casellina che troverai nella sezione riguardante l'indirizzo di consegna.</p>
                <li className="mb-2 title" style={{textDecoration:"underline"}}>18. Per il servizio consegna a domicilio: l’addetto alla consegna non si è presentato all’orario
                    indicato. Cosa faccio?</li>
                <p> L’addetto alla consegna, qualora fosse in ritardo, contatterà direttamente il cliente al numero di
                    telefono inserito al momento dell’ordine. In alternativa il cliente può contattare direttamente il
                    supermercato chiamando al numero di telefono indicato nella sezione NEGOZI o scrivendo a
                    servizioclienti@vivoacasatua.com</p>
                <li className="mb-2 title" style={{textDecoration:"underline"}}>19. È obbligatorio essere registrati?</li>

                <p>  No, la registrazione è obbligatoria esclusivamente per poter effettuare degli acquisti. È possibile
                    navigare sul sito senza registrarsi, i prodotti e i prezzi presentati in questa modalità saranno quelli
                    relativi al punto vendita VIVO di Cirella (CS).</p>
            </ol>
            </div>
            </div>
        );
    }
}

export default Faq;