import React from "react"
import { ReactComponent as GlutenFree } from '../images/tags/ico_so_senzaglut.svg';
import { ReactComponent as Bio } from '../images/tags/ico_so_bio.svg';
import { ReactComponent as Wellness } from '../images/tags/ico_so-welness3.svg';
import { ReactComponent as LactoseFree } from '../images/tags/ico_so_senzalatt.svg';
import { ReactComponent as MaxFormat } from '../images/tags/ico_so_xxl.svg';
// import { ReactComponent as Catering } from '../images/tags/ico_so-pesovar2.svg';
// import { ReactComponent as Etnico } from '../images/tags/ico_so-100ita2.svg';

export const tags = { renderTags, renderDetailTags }

function renderTags(tags) {
    var tag = tags.split(",")[0];
    var icon = null;
    switch (tag) {
        case "SENZA_GLUTINE": icon = <GlutenFree title={"Senza glutine"} />;
            break;
        case "BIOLOGICO": icon = <Bio title={"Biologico"} />
            break;
        case "WELLNESS": icon = <Wellness title={"Wellness"} />
            break;
        case "SENZA_LATTOSIO": icon = <LactoseFree title={"Senza lattosio"} />
            break;
        case "MAXI_FORMATO": icon = <MaxFormat title={"Maxi formato"} />
            break;
        // case "ETNICO": icon = <Etnico />
        //     break;
        // case "CATERING": icon = <Catering />
        //     break;

        default: return icon;
    }

    return icon;
}

function renderDetailTags(tags, item) {
    var tag = tags.split(",")[0];
    var icon = null;
    var style = null;
    var label = null;
    switch (tag) {
        case "SENZA_GLUTINE":
            icon = <GlutenFree title={"Senza glutine"} />;
            label = "SENZA GLUTINE"
            // style = { color: "#F59900" }
            break;
        case "BIOLOGICO":
            icon = <Bio title={"Biologico"} />
            label = "BIOLOGICO"
            break;
        case "WELLNESS":
            icon = <Wellness title={"Wellness"} />
            label = "WELLNESS"
            // style = { color: "#8CB9E5" }
            break;
        case "SENZA_LATTOSIO":
            icon = <LactoseFree title={"Senza lattosio"} />
            label = "SENZA LATTOSIO"
            // style = { color: "#007CDC" }
            break;
        case "MAXI_FORMATO":
            icon = <MaxFormat title={"Maxi formato"} />
            label = `MAXI FORMATO ${item.weight && item.weight}${item.attrib1 && item.attrib1}`
            break;
        // case "ETNICO":
        //     icon = <Etnico />
        //     break;
        // case "CATERING":
        //     icon = <Catering />
        //     break;

        default: return null;
    }

    return (
        <span className="product-detail-heading">
            <span className="pr-2">
                {icon}
            </span>
            <span style={style}>
                {label}
            </span>
        </span>
    );
}