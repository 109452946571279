/**
* Use the CSS tab above to style your Element's container.
*/
import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import './CardSectionStyles.scss'

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            // color: "#32325d",
            // fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            // fontSmoothing: "antialiased",
            fontSize: "14px",
            fontWeight: "bold",
            letterSpacing: "0.6px",
            "::placeholder": {
                color: "#7a7a7a",
                fontWeight: "normal",
                // fontSize: "12px"
            },
        },
        invalid: {
            color: "#dd0202",
            iconColor: "#dd0202",
        },
    },
};

function CardSection() {
    return (

        <div className="d-flex">
            <CardElement options={CARD_ELEMENT_OPTIONS}  />
        </div>

    );
};

export default CardSection;