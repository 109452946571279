import React from "react"
import SearchText from "./SearchText/SearchText"
import SearchResults from "./SearchResults/SearchResults"

export default class Search extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchInput: ""
        }
    }

    onChange = (e) => this.setState({ [e.target.name]: e.target.value });

    componentDidUpdate() {
        if (!this.props.showSearch && this.state.searchInput !== "") {
            this.setState({
                searchInput: ""
            })
        }
    }

    render() {
        const { searchInput } = this.state;
        return (
            <>
                <SearchText
                    searchInput={searchInput}
                    onChange={this.onChange}
                />
                {searchInput.length > 2 ?
                    <SearchResults
                        input={searchInput}
                        onClick={this.props.onClick}
                    />
                    : null
                }
            </>
        );
    }
}